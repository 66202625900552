import { gql } from '@apollo/client';
import { Folder, FolderTypeEnum } from 'types';

export const CREATE_FOLDER_MUTATION = gql`
  mutation CreateFolder($input: CreateFolderInput!) {
    createFolder(input: $input) {
      _id
      referenceId
      name
      type
      isStandardFolder
      parentFolder {
        _id
        name
      }
      project {
        _id
        name
        referenceId
      }
      lead {
        _id
        referenceId
        name
      }
    }
  }
`;

export type CreateFolderMutationResponse = {
  createFolder: Folder;
};

export type CreateFolderMutationVariables = {
  input: {
    name: string;
    type: FolderTypeEnum;
    isStandardFolder: boolean;
    parentFolder?: string;
    project?: string;
    lead?: string;
  };
};

export const UPDATE_FOLDER_MUTATION = gql`
  mutation UpdateFolder($input: UpdateFolderInput!) {
    updateFolder(input: $input) {
      _id
      referenceId
      name
      type
      isStandardFolder
      parentFolder {
        _id
        name
      }
      project {
        _id
        name
        referenceId
      }
      lead {
        _id
        referenceId
        name
      }
    }
  }
`;

export type UpdateFolderMutationResponse = {
  updateFolder: Folder;
};

export type UpdateFolderMutationVariables = {
  input: {
    _id: string;
    name: string;
  };
};
