import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import {
  CREATE_FOLDER_MUTATION,
  CreateFolderMutationResponse,
  CreateFolderMutationVariables,
} from 'graphql/mutation/folder';
import {
  GET_FOLDERS_QUERY_FOR_LV,
  GetFoldersQueryResponse,
  GetFoldersQueryVariables,
} from 'graphql/query/folder';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Folder, FolderTypeEnum } from 'types';

import { DocumentFoldersGrid } from 'components/DocumentMaster/DocumentFolders';
import TextField from 'components/Inputs/TextField';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import { SimplePopup } from 'components/Popup';

import { updateFolderCachedList } from '../DocumentFolders';

const DocumentSubFoldersPage = () => {
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [enableCreateNewForm, toggleCreateNewForm] = useState(false);
  const navigate = useNavigate();

  const {
    documentId = '',
    folderType = '',
    folderId = '',
  } = useParams<{
    folderType: FolderTypeEnum;
    documentId: string;
    folderId: string;
  }>();

  const {
    data: folders,
    networkStatus,
    refetch: refetchFolders,
  } = useQuery<GetFoldersQueryResponse, GetFoldersQueryVariables>(GET_FOLDERS_QUERY_FOR_LV, {
    notifyOnNetworkStatusChange: true,
    variables: {
      type: folderType as FolderTypeEnum,
      projectOrLeadId: documentId,
      parentFolder: folderId,
    },
  });

  if (!Object.values(FolderTypeEnum).includes(folderType as FolderTypeEnum)) {
    navigate('/companies');
    return null;
  }

  const folderActions = [
    <Button
      variant="contained"
      key="create-subfolder"
      size="small"
      onClick={() => toggleCreateNewForm(true)}
    >
      <AddIcon fontSize="small" />
    </Button>,
  ];

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
    refetchFolders(newFilters);
  };

  const loadingFolders =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!folders;

  return (
    <Navbar
      goBackButtonConfig={{
        title: !!!folders?.getFolders?.title ? 'Project / Lead Name' : folders?.getFolders.title,
      }}
      searchInputConfig={{
        enable: true,
        name: 'SubFolder search',
        placeholder: 'Folder Name',
        handleChange: searchFilter =>
          applyFilters({
            ...filters,
            searchTerm: !!searchFilter ? searchFilter : null,
          }),
      }}
      actionChildren={folderActions}
    >
      {loadingFolders ? (
        <LoadingIndicator />
      ) : (
        <DocumentFoldersGrid folders={folders?.getFolders?.folders ?? []} />
      )}
      <SimplePopup
        title="Create a new folder"
        fullWidth
        onClose={() => toggleCreateNewForm(false)}
        open={enableCreateNewForm}
      >
        <CreateFolder cb={() => toggleCreateNewForm(false)} />
      </SimplePopup>
    </Navbar>
  );
};

const CreateFolder: React.FC<{ cb: (arg: Folder) => void }> = ({ cb }) => {
  const [formState, setFormState] = useState<Record<string, any>>({});

  const [createFolder, { loading: creatingFolder }] = useMutation<
    CreateFolderMutationResponse,
    CreateFolderMutationVariables
  >(CREATE_FOLDER_MUTATION);

  const {
    documentId = '',
    folderType = '',
    folderId = '',
  } = useParams<{
    folderType: FolderTypeEnum;
    documentId: string;
    folderId: string;
  }>();

  const handleChange = (fieldName: string, val: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: val,
    }));
  };

  const handleSubmit = () => {
    createFolder({
      variables: {
        input: {
          name: formState.name,
          type: folderType as FolderTypeEnum,
          parentFolder: folderId,
          lead: folderType === FolderTypeEnum.LEAD ? documentId : undefined,
          project: folderType === FolderTypeEnum.PROJECT ? documentId : undefined,
          isStandardFolder: false,
        },
      },
      onCompleted: res => {
        updateFolderCachedList(res.createFolder, {
          type: folderType as FolderTypeEnum,
          projectOrLeadId: documentId,
          parentFolder: folderId,
        });
        cb(res.createFolder);
      },
    });
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid container direction="column" gap={2} px={2} py={1}>
        <TextField
          label="Folder Name"
          value={formState.name ?? ''}
          onChange={e => handleChange('name', e.target.value)}
          required
        />
        <LoadingButton variant="contained" type="submit" loading={creatingFolder}>
          Create
        </LoadingButton>
      </Grid>
    </form>
  );
};

export default DocumentSubFoldersPage;
