import { NetworkStatus, gql, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Lead, LeadSegmentEnum, LeadSortOptionEnum, LeadStatusEnum, MonthTypeEnum } from 'types';

import LeadsTemplate from 'components/Leads';
import { Sort } from 'components/Leads/Sort/Sort';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';

const GET_LEADS_QUERY = gql`
  query GetLeads($filter: LeadFilter) {
    getLeads(filter: $filter) {
      _id
      referenceId
      name
      scope
      segment
      areaOrPower
      areaOrPowerUOM
      approxValue
      probability
      actualPODate
      expectedPODate
      pqSubmittedDate
      pqReceivedDate
      rfpReceivedDate
      rfpSubmittedDate
      leadStatus
    }
  }
`;

type GetLeadsQueryResponse = {
  getLeads: Lead[];
};

type GetLeadsQueryVariables = {
  filter: {
    leadStatus?: LeadStatusEnum[];
    monthType?: MonthTypeEnum;
    segment?: LeadSegmentEnum[];
    searchTerm?: string;
    sortOption?: LeadSortOptionEnum;
  };
};

const LeadsPage = () => {
  const [filters, setFilters] = useState<Record<string, any>>({});

  const {
    data: leads,
    networkStatus,
    refetch: refetchLeads,
  } = useQuery<GetLeadsQueryResponse, GetLeadsQueryVariables>(GET_LEADS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const navigate = useNavigate();

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
    refetchLeads({
      filter: newFilters,
    });
  };

  const loadingLeads =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!leads;

  return (
    <Navbar
      title="Leads"
      searchInputConfig={{
        enable: true,
        name: 'Search',
        placeholder: 'Search Leads',
        handleChange: searchFilter =>
          applyFilters({
            ...filters,
            searchTerm: !!searchFilter ? searchFilter : null,
          }),
      }}
      actionChildren={
        <Button
          variant="contained"
          size="small"
          onClick={() => navigate('create-lead')}
          color="secondary"
        >
          <AddIcon fontSize="small" />
        </Button>
      }
    >
      <Sort
        sort={filters.sortOption}
        setSort={newSortVal =>
          applyFilters({
            ...filters,
            sortOption: newSortVal,
          })
        }
      />
      {loadingLeads ? <LoadingIndicator size="1.6rem" /> : <LeadsTemplate leads={leads.getLeads} />}
    </Navbar>
  );
};

export default LeadsPage;
