import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { DocumentFile, FolderTypeEnum } from 'types';

import MetadataForm from 'components/Inputs/Metadata';
import TextField from 'components/Inputs/TextField';
import UploadFile from 'components/Inputs/UploadFile';

const UPLOAD_FILE_MUTATION = gql`
  mutation UploadFiles($input: UploadFileInput!) {
    uploadFiles(input: $input) {
      _id
      referenceId
      folder {
        _id
        referenceId
        name
      }
      name
      path
      metadata
      project {
        _id
        referenceId
        name
      }
      lead {
        _id
        referenceId
        name
      }
      createdAt
      createdBy {
        _id
        firstName
        lastName
      }
    }
  }
`;

type UploadFileMutationResponse = {
  uploadFiles: DocumentFile[];
};

type UploadFileMutationVariables = {
  input: {
    folder: string;
    fileDetails: {
      fileName: string;
      file: any;
      metadata: string;
    }[];
    project?: string;
    lead?: string;
  };
};

const UPDATE_FILE_MUTATION = gql`
  mutation UpdateFile($id: ID!, $fileDetail: UpdateFileMetadataInput!) {
    updateFile(_id: $id, fileDetail: $fileDetail) {
      _id
      referenceId
      folder {
        _id
        referenceId
        name
      }
      name
      path
      metadata
      project {
        _id
        referenceId
        name
      }
      lead {
        _id
        referenceId
        name
      }
      createdAt
      createdBy {
        _id
        firstName
        lastName
      }
    }
  }
`;

type UpdateFileMutaitonResponse = {
  updateFile: DocumentFile;
};

type UpdateFileMutationVariables = {
  id: string;
  fileDetail: {
    metadata?: string;
    file?: File;
    fileName?: string;
  };
};

const DocumentFileForm: React.FC<{
  initialData?: {
    _id: string;
    fileName: string;
    path: string;
    metadata: { key: string; value: any }[];
    file: DocumentFile;
  };
  folderId?: string;
  cb: (arg: DocumentFile) => void;
}> = ({ initialData, folderId = '', cb }) => {
  const [formState, setFormState] = useState<Record<string, any>>(initialData ?? {});
  const [fileError, setFileError] = useState('');

  const { folderType = '', documentId = '' } = useParams<{
    folderType: FolderTypeEnum;
    documentId: string;
  }>();

  const [uploadFiles, { loading: uploadingFiles }] = useMutation<
    UploadFileMutationResponse,
    UploadFileMutationVariables
  >(UPLOAD_FILE_MUTATION);

  const [updateFile, { loading: updatingFile }] = useMutation<
    UpdateFileMutaitonResponse,
    UpdateFileMutationVariables
  >(UPDATE_FILE_MUTATION);

  const handleChange = (fieldName: string, value: any) => {
    if (!!fileError) setFileError('');

    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleSubmit = () => {
    if (!!!formState.file) {
      setFileError('Please Upload a file');
      return;
    }

    const isLead = folderType === FolderTypeEnum.LEAD;
    const { _id, ...data } = formState;
    const metadata = {};
    (data.metadata ?? []).forEach(d => {
      if (!!d.key) {
        metadata[d.key] = d.value;
      }
    });

    if (!!_id) {
      updateFile({
        variables: {
          id: _id,
          fileDetail: {
            file: data.file instanceof File ? data.file : data.path,
            fileName: data.fileName,
            metadata: JSON.stringify(metadata),
          },
        },
        onCompleted: res => cb(res.updateFile),
      });
    } else {
      uploadFiles({
        variables: {
          input: {
            folder: folderId,
            lead: isLead ? documentId : undefined,
            project: isLead ? undefined : documentId,
            fileDetails: [
              {
                fileName: data.fileName,
                file: data.file,
                metadata: JSON.stringify(metadata),
              },
            ],
          },
        },
        onCompleted: res => cb(res.uploadFiles[0]),
      });
    }
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid container columnSpacing={1.5} rowGap={2} px={2} pb={3} pt={1}>
        <Grid item xs={12}>
          <TextField
            label="File Name"
            value={formState.fileName ?? ''}
            onChange={e => handleChange('fileName', e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <UploadFile
            values={formState.file ?? ''}
            label="Add File *"
            onChange={val => handleChange('file', val)}
            helperText={!!fileError ? fileError : undefined}
            accept="*"
            required={!!!formState.file}
          />
        </Grid>
        <Grid item xs={12}>
          <MetadataForm
            metadata={formState.metadata ?? [{ key: ``, value: `` }]}
            setMetadata={md =>
              setFormState(prev => ({
                ...prev,
                metadata: md,
              }))
            }
          />
        </Grid>
        <Grid container item xs={12}>
          <LoadingButton
            loading={uploadingFiles || updatingFile}
            variant="contained"
            sx={{ ml: 'auto' }}
            type="submit"
          >
            {!!initialData?._id ? 'Update File' : 'Upload File'}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default DocumentFileForm;
