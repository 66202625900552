import { useEffect, useState } from 'react';
import { DocumentMasterStatus, DocumentTypeEnum, FilterProps, FiltersVariant } from 'types';

enum DocumentMasterType {
  'LEAD' = 'LEAD',
  'PROJECT' = 'PROJECT',
}

const getFilesFilterSchema = (initialData: any) => {
  const { type, status } = initialData;

  const filterSchema: FilterProps[] = [
    {
      fieldName: 'type',
      type: 'multiple_select',
      label: 'Document Type',
      fullWidth: true,
      placeholder: 'Type',
      defaultValue: !!type ? type : [],
      options: Object.keys(DocumentTypeEnum).map(k => ({
        label: k,
        value: DocumentTypeEnum[k],
      })),
    },
    {
      fieldName: 'status',
      type: 'selection',
      label: 'Document Status',
      fullWidth: true,
      defaultValue: status ?? [null],
      options: Object.keys(DocumentMasterStatus).map(k => ({
        label: k,
        value: DocumentMasterStatus[k],
      })),
    },
  ];
  return filterSchema;
};

const getDocumentsFilterSchema = (initialData: any) => {
  const { type } = initialData;

  const filterSchema: FilterProps[] = [
    {
      fieldName: 'type',
      type: 'multiple_select',
      label: 'Document Type',
      fullWidth: true,
      placeholder: 'Type',
      defaultValue: !!type ? type : [],
      options: Object.keys(DocumentMasterType).map(k => ({
        label: k,
        value: DocumentMasterType[k],
      })),
    },
  ];
  return filterSchema;
};

const useFilterSchema = (type: FiltersVariant, initialData: any) => {
  const [filterSchema, setFilterSchema] = useState<any[]>([]);

  useEffect(() => {
    const getfilterSchema = () => {
      let filters: FilterProps[];
      switch (type) {
        case 'document-master':
          filters = getDocumentsFilterSchema(initialData ?? {});
          break;
        case 'file-list':
          filters = getFilesFilterSchema(initialData ?? {});
          break;
        default:
          filters = [];
          break;
      }
      setFilterSchema(filters);
    };
    getfilterSchema();
  }, [type, initialData]);

  return [filterSchema];
};

export default useFilterSchema;
