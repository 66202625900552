import EditNoteIcon from '@mui/icons-material/EditNote';
import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { RFP } from 'types';

import { SimplePopup } from 'components/Popup';
import RFPUpdateForm from 'components/RFPs/Forms/RFPUpdateForm';
import Section from 'components/Section';

const RFPSection: React.FC<{ rfp: RFP }> = ({ rfp }) => {
  const [showEditForm, toggleEditForm] = useState(false);

  return (
    <>
      <Section
        title="RFP Details"
        collapsible
        action={<EditNoteIcon onClick={() => toggleEditForm(true)} sx={{ cursor: 'pointer' }} />}
        data={[
          {
            label: 'RFP ID',
            value: rfp.referenceId,
            type: 'STRING',
          },
          {
            label: 'PQ',
            value: rfp.pq.referenceId,
            type: 'LINK',
            navigateTo: `/pqs/${rfp.pq._id}`,
          },
          {
            label: 'Lead',
            value: rfp.lead.name,
            type: 'LINK',
            navigateTo: `/leads/${rfp.lead._id}`,
          },
          {
            label: 'Created By',
            value: rfp.createdBy.firstName + ' ' + rfp.createdBy.lastName ?? '',
            type: 'STRING',
          },
        ]}
      />
      <SimplePopup
        onClose={() => toggleEditForm(false)}
        open={showEditForm}
        title="Update RFP"
        fullWidth
      >
        <Grid px={2} py={1}>
          <RFPUpdateForm cb={() => toggleEditForm(false)} rfp={rfp} />
        </Grid>
      </SimplePopup>
    </>
  );
};

export default RFPSection;
