import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  Typography,
} from '@mui/material';
import React from 'react';

import LoadingIndicator from 'components/LoadingIndicator';

type SelectProps = {
  options: { label: string; value: string }[];
  onChange?: (arg: any) => void;
  showNone?: boolean;
  fetchConfig?: {
    fetchFn: any;
    variables: Record<string, any>;
    loading: boolean;
  };
} & MuiSelectProps;

export const Select: React.FC<SelectProps> = ({
  label,
  error,
  size = 'medium',
  value,
  onChange = () => {},
  options,
  placeholder,
  required,
  showNone = false,
  fetchConfig,
  ...props
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel error={error && error} id={label + '-label'} required={required}>
        {label}
      </InputLabel>
      <MuiSelect
        labelId={label + '-label'}
        label={label}
        size={size}
        value={value}
        error={error && error}
        onOpen={() =>
          !!fetchConfig && !!fetchConfig.fetchFn
            ? fetchConfig.fetchFn({ variables: fetchConfig.variables })
            : undefined
        }
        endAdornment={
          fetchConfig?.loading && (
            <Box mr={2}>
              <LoadingIndicator size="1rem" />
            </Box>
          )
        }
        onChange={e => onChange(e.target.value)}
        renderValue={value => (value ? options?.find(o => o.value === value)?.label : placeholder)}
        required={required}
        {...props}
      >
        {showNone && (
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        )}
        {options &&
          options.map(o => (
            <MenuItem key={o.value} value={o.value}>
              {o.label}
            </MenuItem>
          ))}
      </MuiSelect>
    </FormControl>
  );
};

export const MultiSelect: React.FC<{
  label: string;
  value: any[];
  handleChange: (arg: any) => void;
  options: {
    label: string;
    value: any;
  }[];
  required?: boolean;
  disabled?: boolean;
  fetchConfig?: {
    fetchFn: any;
    variables: Record<string, any>;
    loading: boolean;
  };
  addNewConfig?: {
    enableAddNew: boolean;
    onClick: () => void;
    addNewLabel?: string;
  };
  size?: 'small' | 'medium';
}> = ({
  label,
  value,
  handleChange,
  options = [],
  required = false,
  fetchConfig,
  disabled = false,
  size = 'medium',
  addNewConfig = {
    enableAddNew: false,
    onClick: () => {},
  },
}) => {
  return (
    <FormControl fullWidth disabled={disabled}>
      <InputLabel id={label + '-label'} required={required}>
        {label}
      </InputLabel>
      <MuiSelect
        size={size}
        multiple
        labelId={label + '-label'}
        label={label}
        name={label}
        id={label}
        value={value}
        onOpen={() =>
          !!fetchConfig && !!fetchConfig.fetchFn
            ? fetchConfig.fetchFn({ variables: fetchConfig.variables })
            : undefined
        }
        required={required}
        onChange={e => {
          if (typeof e.target.value === 'string') {
            handleChange(e.target.value.split(','));
          } else if (Array.isArray(e.target.value)) {
            const selectedOptions = e.target.value.filter(val => val !== undefined);
            handleChange(selectedOptions);
          } else {
            handleChange(e.target.value);
          }
        }}
        endAdornment={
          fetchConfig?.loading && (
            <Box mr={2}>
              <LoadingIndicator size="1rem" />
            </Box>
          )
        }
        renderValue={selected => {
          return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map(value => {
                return value !== undefined ? (
                  <Chip
                    key={JSON.stringify(value)}
                    size="small"
                    label={options?.find(o => o.value === value)?.label}
                  />
                ) : null;
              })}
            </Box>
          );
        }}
        MenuProps={{ PaperProps: { sx: { maxHeight: '75%' } } }}
      >
        {!!options.length ? (
          options.map(o => (
            <MenuItem key={JSON.stringify(o.value)} value={o.value}>
              <Checkbox size="small" checked={value.indexOf(o.value) > -1} />
              {o.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No options available</MenuItem>
        )}
        {addNewConfig.enableAddNew && (
          <Box
            sx={{
              bgcolor: theme => theme.palette.primary.light,
              color: 'white',
              py: 0.8,
              cursor: 'pointer',
              mb: -1,
            }}
          >
            {
              <Typography
                variant="subtitle2"
                textAlign="center"
                onClick={addNewConfig.onClick}
                width="100%"
              >
                {!!addNewConfig.addNewLabel ? addNewConfig.addNewLabel : '+ Add New'}
              </Typography>
            }
          </Box>
        )}
      </MuiSelect>
    </FormControl>
  );
};
