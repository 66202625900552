import { Box, Chip, Divider, Grid, Typography } from '@mui/material';
import { useLeadDashboard } from 'contexts/leadDashboardContext';
import { useNavigate } from 'react-router-dom';
import { LeadDashboardType, LeadStatusEnum } from 'types';

import LeadFilterButton from '../FilterButton';

const LeadWonAndLossFilterTemplate: React.FC<{ dashboard: LeadDashboardType }> = ({
  dashboard,
}) => {
  const { setFilters } = useLeadDashboard();
  const navigate = useNavigate();

  const handleClick = (type: LeadStatusEnum) => {
    setFilters(prev => ({
      segment: prev.segment,
      leadStatus: [type],
    }));
    navigate('dashboard-leads');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        rowGap={2}
        columnSpacing={2}
        width="100%"
        maxWidth={'700px'}
        mx="auto"
      >
        <Grid item xs={12} mt={2}>
          <Divider variant="middle">
            <Typography variant="button" fontWeight={700}>
              <Chip label="WON / LOST" size="small" variant="outlined" color="secondary" />
            </Typography>
          </Divider>
        </Grid>
        <Grid item xs={6}>
          <LeadFilterButton
            amount={dashboard.wonLeads.totalApproxValue}
            leadsCount={dashboard.wonLeads.leadCount}
            label="Won"
            onClick={() => handleClick(LeadStatusEnum.WON)}
          />
        </Grid>
        <Grid item xs={6}>
          <LeadFilterButton
            amount={dashboard.lostLeads.totalApproxValue}
            leadsCount={dashboard.lostLeads.leadCount}
            label="Lost"
            onClick={() => handleClick(LeadStatusEnum.LOST)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LeadWonAndLossFilterTemplate;
