import { Grid } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Lead, LeadScopeEnum, LeadSegmentEnum, LeadStatusEnum, Project } from 'types';

import Card from 'components/Card';

import { truncateString } from 'utils/common';
import { formatDate, getEnumKeyFromValue } from 'utils/transformFn';

export type LeadOrProjectType = (Lead & Project) & { isLeadOrProject: string };

const DocumentsMasterTemplate: React.FC<{
  documentsMaster: LeadOrProjectType[];
}> = ({ documentsMaster }) => {
  return (
    <Grid container columnSpacing={2.5} rowSpacing={3} py={2}>
      {documentsMaster.map(doc => (
        <Grid item key={doc._id} xs={12} sm={6} md={4} px={1}>
          <DocumentMasterCard doc={doc} />
        </Grid>
      ))}
    </Grid>
  );
};

const DocumentMasterCard: React.FC<{ doc: LeadOrProjectType }> = ({ doc }) => {
  const navigate = useNavigate();

  const isLead = doc.isLeadOrProject === 'Lead';
  const cardData: { label: string; value: any }[] = [];

  if (isLead) {
    const leadData = [
      {
        label: 'Scope',
        value: getEnumKeyFromValue(LeadScopeEnum, doc.scope),
      },
      {
        label: 'Segment',
        value: getEnumKeyFromValue(LeadSegmentEnum, doc.segment),
      },
      {
        label: 'Approx Value',
        value: `₹ ${doc.approxValue} Cr`,
      },
      {
        label: 'City',
        value: doc.address.city,
      },
    ];

    cardData.push(...leadData);
  } else {
    const projectData = [
      {
        label: 'Start Date',
        value: formatDate(new Date()),
      },
      {
        label: 'End Date',
        value: formatDate(new Date()),
      },
      {
        label: 'Type',
        value: LeadScopeEnum.STRUCTURE,
      },
      {
        label: 'City',
        value: 'Pune',
      },
    ];
    cardData.push(...projectData);
  }

  const status = isLead
    ? (getEnumKeyFromValue(LeadStatusEnum, doc.leadStatus) as string)
    : LeadStatusEnum.CREATED;

  return (
    <Card
      headers={[truncateString(doc.name, 10), doc.isLeadOrProject, status]}
      data={cardData}
      onClick={() => navigate(`${doc.isLeadOrProject.toUpperCase()}/${doc._id}`)}
    />
  );
};

export default DocumentsMasterTemplate;
