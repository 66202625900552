import { gql } from '@apollo/client';
import { DocumentFile } from 'types';

export const GET_DOCUMENT_FILES_QUERY = gql`
  query GetFiles($folder: ID!, $project: ID, $lead: ID, $searchTerm: String) {
    getFiles(folder: $folder, project: $project, lead: $lead, searchTerm: $searchTerm) {
      _id
      referenceId
      name
      path
      metadata
      createdAt
      createdBy {
        _id
        firstName
        lastName
      }
    }
  }
`;

export type GetFilesQueryResponse = {
  getFiles: DocumentFile[];
};
export type GetFilesQueryVariables = {
  folder: string;
  project?: string;
  lead?: string;
  searchTerm?: string;
};
