import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DocumentFile } from 'types';

import Card from 'components/Card';

import Documenticon from 'media/icons/document';
import Excelicon from 'media/icons/excel';
import MicrosoftProjecticon from 'media/icons/microsoft-project';
import Pdficon from 'media/icons/pdficon';
import Ppticon from 'media/icons/ppt';
import WordIcon from 'media/icons/word';

import { truncateString } from 'utils/common';

import DocumentActions from './Actions';

const getFileIconFromPath = (path: string) => {
  const extension = path.split('.').pop()?.toLowerCase();

  switch (extension) {
    case 'pdf':
      return <Pdficon />;
    case 'ppt':
    case 'pptx':
      return <Ppticon />;
    case 'xls':
    case 'xlsx':
      return <Excelicon />;
    case 'doc':
    case 'docx':
      return <WordIcon />;
    case 'mpp':
      return <MicrosoftProjecticon />;
    // case 'jpg':
    // case 'jpeg':
    // case 'png':
    //   return
    default:
      return <Documenticon />;
  }
};

const DocumentFileCard: React.FC<{ file: DocumentFile }> = ({ file }) => {
  const navigate = useNavigate();

  const metadataKeys = Object.keys(file.metadata ?? {});

  return (
    <Grid item key={file.referenceId} width={'100%'} md={1.5} lg={1}>
      <Card headers={[truncateString(file.name, 15), 'ACTIVE']}>
        <Grid container justifyContent="space-between" onClick={() => navigate(file._id)}>
          <Grid item xs={9}>
            <Grid container direction="column" rowGap={0.5}>
              {metadataKeys.map((k, i) => (
                <LineItem key={i} label={k} value={file.metadata[k]} />
              ))}
              {metadataKeys.length <= 2 && file.createdBy && (
                <LineItem
                  key="createdBY"
                  label="Created By"
                  value={`${file.createdBy.firstName} ${file.createdBy.lastName}`}
                />
              )}
            </Grid>
          </Grid>

          <Grid item xs={3} container justifyContent="center" alignItems="flex-end">
            {file.path && getFileIconFromPath(file.path)}
          </Grid>
        </Grid>

        <DocumentActions file={file} />
      </Card>
    </Grid>
  );
};

const LineItem: React.FC<{ label: string; value: any }> = ({ label, value }) => {
  return (
    <Grid item container alignItems="center">
      <Grid item xs={4}>
        <Typography variant="caption" fontWeight={500} color="darkslategray">
          {label}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="body1" fontWeight={600}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DocumentFileCard;
