import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { GET_ME_QUERY, GetMeQueryResponse } from 'graphql/query/get-me';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import FocusLayout from 'layouts/FocusLayout';

import { storeLoginCredentials } from 'utils/auth';

type LoginMutationResponse = {
  login: {
    sessionToken: string;
  };
};

type LoginMutationVariables = {
  email: string;
  password: string;
};

const LOGIN_USER = gql`
  mutation Mutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      sessionToken
      message
    }
  }
`;

const LoginPage: FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const [loginUser, { loading: loggingIn }] = useMutation<
    LoginMutationResponse,
    LoginMutationVariables
  >(LOGIN_USER);

  const [getMe, { loading: loadingUser }] = useLazyQuery<GetMeQueryResponse>(GET_ME_QUERY, {
    fetchPolicy: 'network-only',
  });

  const [showErrorMsg, setErrorMsg] = useState(false);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorMsg(false);
    loginUser({ variables: { email: email, password: password } })
      .then(data => {
        const token = data.data?.login?.sessionToken;
        if (token) {
          storeLoginCredentials(token);
          getMe({
            onCompleted: usr => {
              const redirectTo = usr.getMe.passwordResetRequired ? '/settings' : '/';
              navigate(redirectTo);
            },
          });
        } else setErrorMsg(true);
      })
      .catch(error => console.log(error));
  };

  return (
    <FocusLayout>
      <form onSubmit={onSubmit}>
        <Grid container flexDirection="column" gap={2}>
          <Grid item xs={12}>
            <Typography textAlign="center" variant="h2" color={theme => theme.palette.primary.main}>
              LOGIN
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField value={email} onChange={e => setEmail(e.target.value)} label="Email" />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                value={password}
                onChange={e => setPassword(e.target.value)}
                label="Password"
                type={showPassword ? 'text' : 'password'}
                autoComplete=""
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item container flexDirection="column" rowGap={0.5} mt={-1}>
            <Grid item xs={12} container>
              {showErrorMsg && (
                <Typography variant="caption" color="red">
                  Enter a valid email or password
                </Typography>
              )}
              <Typography ml={'auto'} pl={1}>
                <Link to={'/forgot-password'}>Forgot password?</Link>
              </Typography>
            </Grid>
            <LoadingButton
              loading={loggingIn || loadingUser}
              disabled={!email || !password}
              variant="contained"
              type="submit"
              fullWidth
            >
              Submit
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </FocusLayout>
  );
};
export default LoginPage;
