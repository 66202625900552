import { NetworkStatus, gql, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RFP } from 'types';

import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import RFPTemplate from 'components/RFPs';

const GET_RFP_QUERY = gql`
  query GetRfps {
    getRfps {
      _id
      referenceId
      tenderDetails {
        billingType
        submissionMode
      }
      po {
        poOrLOIReferenceNumber
      }
      tenderCommercials {
        costType
        costSubType
        costValue
        priceValue
        marginValue
      }
    }
  }
`;

type GetRfpQueryResponse = {
  getRfps: RFP[];
};

type GetRfpQueryVariables = {
  filter: {
    searchTerm?: string;
  };
};

const RFPPage = () => {
  const [filters, setFilters] = useState<Record<string, any>>({});
  const {
    data: rfps,
    networkStatus,
    refetch: refetchRfps,
  } = useQuery<GetRfpQueryResponse, GetRfpQueryVariables>(GET_RFP_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const navigate = useNavigate();

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
    refetchRfps({
      filter: newFilters,
    });
  };

  const loadingRFPs =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!rfps;

  return (
    <Navbar
      title="RFP"
      searchInputConfig={{
        enable: true,
        name: 'RFPs search',
        placeholder: 'ID / Name',
        handleChange: searchFilter =>
          applyFilters({
            ...filters,
            searchTerm: !!searchFilter ? searchFilter : null,
          }),
      }}
      actionChildren={
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={() => navigate('create-rfp')}
        >
          <AddIcon fontSize="small" />
        </Button>
      }
    >
      {loadingRFPs ? <LoadingIndicator size="1.6rem" /> : <RFPTemplate rfps={rfps.getRfps} />}
    </Navbar>
  );
};

export default RFPPage;
