import Box from '@mui/material/Box';
import { DataGrid, DataGridProps, GridColDef } from '@mui/x-data-grid';
import * as React from 'react';

type DataGridTableProps = {
  rows: Record<string, any>[];
  columns: GridColDef[];
} & DataGridProps;

const DataGridTable: React.FC<DataGridTableProps> = ({ columns, rows, ...props }) => {
  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        // pageSizeOptions={[5]}
        disableRowSelectionOnClick
        {...props}
      />
    </Box>
  );
};

export default DataGridTable;
