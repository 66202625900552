import { gql, useMutation } from '@apollo/client';
import React from 'react';
import { CostSubTypeEnum, CostTypeEnum, RfpTenderCommercials } from 'types';

import { FormInput, FormPanel } from 'components/FormPanel';

const UPDATE_COMMERCIALS_MUTATION = gql`
  mutation UpdateRfpTenderCommercials($input: UpdateRfpTenderCommercialsInput!) {
    updateRfpTenderCommercials(input: $input) {
      _id
      costType
      costSubType
      costValue
      priceValue
      marginValue
    }
  }
`;

type UpdateCommercialsMutationResponse = {
  updateRfpTenderCommercials: RfpTenderCommercials;
};
type UpdateCommercialsMutationVariables = {
  input: {
    _id: string;
    costType: CostTypeEnum;
    costSubType: CostSubTypeEnum;
    priceValue: number;
    costValue: number;
    marginValue: number;
  };
};

const UpdateCommercialsForm: React.FC<{
  cb: (arg: RfpTenderCommercials) => void;
  commercial: RfpTenderCommercials;
}> = ({ cb, commercial }) => {
  const [updateCommercials, { loading: updatingCommercials }] = useMutation<
    UpdateCommercialsMutationResponse,
    UpdateCommercialsMutationVariables
  >(UPDATE_COMMERCIALS_MUTATION);

  const handleSubmit = (data: RfpTenderCommercials) => {
    updateCommercials({
      variables: {
        input: {
          _id: commercial._id,
          costType: data.costType,
          costSubType: data.costSubType,
          costValue: Number(data.costValue),
          marginValue: Number(data.marginValue),
          priceValue: Number(data.priceValue),
        },
      },
      onCompleted: res => cb(res.updateRfpTenderCommercials),
    });
  };

  return (
    <FormPanel
      error={null}
      loading={updatingCommercials}
      onSubmit={handleSubmit}
      submitButtonLabel="Update"
    >
      <FormInput
        fieldName="costType"
        label="Cost Type"
        options={Object.keys(CostTypeEnum).map(k => ({ label: k, value: CostTypeEnum[k] }))}
        type="select"
        defaultValue={commercial.costType}
        validators={{
          required: true,
        }}
      />
      <FormInput
        fieldName="costSubType"
        label="Cost Sub Type"
        options={Object.keys(CostSubTypeEnum).map(k => ({ label: k, value: CostSubTypeEnum[k] }))}
        type="select"
        defaultValue={commercial.costSubType}
        validators={{
          required: true,
        }}
      />
      <FormInput
        fieldName="costValue"
        type="currency"
        label="Cost Value"
        defaultValue={commercial.costValue}
        validators={{
          required: true,
        }}
      />
      <FormInput
        fieldName="priceValue"
        type="currency"
        label="Price Value"
        defaultValue={commercial.priceValue}
        validators={{
          required: true,
        }}
      />
      <FormInput
        fieldName="marginValue"
        type="currency"
        label="Margin Value"
        defaultValue={commercial.marginValue}
        validators={{
          required: true,
        }}
      />
    </FormPanel>
  );
};

export default UpdateCommercialsForm;
