import { gql, useMutation } from '@apollo/client';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { BillingTypeEnum, RfpTenderDetails, SubmissionModeEnum } from 'types';

import { SimplePopup } from 'components/Popup';
import TenderDetailsForm from 'components/RFPs/Forms/TenderDetailsForm';
import Section from 'components/Section';

import { formatDate, getEnumKeyFromValue } from 'utils/transformFn';

const UPDATE_RFP_TENDER_DETAILS_MUTATION = gql`
  mutation UpdateRfpTenderDetails($input: UpdateRfpTenderDetailsInput!) {
    updateRfpTenderDetails(input: $input) {
      _id
      referenceId
      coefficient
      discount
      retention
      startDate
      endDate
      billingType
      submissionMode
      siteVisited
      isGovtTender
      emdNumber
      emdAmount
      emdEndDate
      emdNotifyBeforeDate
      emdDocument {
        _id
        name
        path
      }
    }
  }
`;

type UpdateRfpTenderMutationResponse = {
  updateRfpTenderDetails: RfpTenderDetails;
};

type UpdateRfpTenderMutationVariables = {
  input: any;
};

const TenderDetailsSection: React.FC<{ rfpTenderDetails: RfpTenderDetails }> = ({
  rfpTenderDetails,
}) => {
  const [showEditForm, toggleEditForm] = useState(false);
  const initialData = {
    ...rfpTenderDetails,
    startDate: !!rfpTenderDetails.startDate ? dayjs(rfpTenderDetails.startDate) : null,
    endDate: !!rfpTenderDetails.endDate ? dayjs(rfpTenderDetails.endDate) : null,
    emdEndDate: !!rfpTenderDetails.emdEndDate ? dayjs(rfpTenderDetails.emdEndDate) : null,
    emdNotifyBeforeDate: !!rfpTenderDetails.emdNotifyBeforeDate
      ? dayjs(rfpTenderDetails.emdNotifyBeforeDate)
      : null,
  };

  const [formState, setFormState] = useState<{ tenderDetails: Record<string, any> }>({
    tenderDetails: initialData,
  });

  const [updateRFPTender, { loading: updating }] = useMutation<
    UpdateRfpTenderMutationResponse,
    UpdateRfpTenderMutationVariables
  >(UPDATE_RFP_TENDER_DETAILS_MUTATION);

  const handleSubmit = () => {
    const td = formState.tenderDetails;
    const file = td.emdDocument;

    delete td.referenceId;
    delete td.__typename;

    if (!td.isGovtTender) {
      delete td.emdAmount;
      delete td.emdDocument;
      delete td.emdEndDate;
      delete td.emdNotifyBeforeDate;
      delete td.emdNumber;
    }

    updateRFPTender({
      variables: {
        input: {
          _id: rfpTenderDetails._id,
          ...td,
          siteVisited: td.siteVisited,
          isGovtTender: td.isGovtTender,
          emdAmount: !!td.emdAmount ? Number(td.emdAmount) : undefined,
          emdDocument: file ?? null,
          startDate: formatDate(td.startDate),
          endDate: formatDate(td.endDate),
          emdEndDate: formatDate(td.emdEndDate),
          emdNotifyBeforeDate: formatDate(td.emdNotifyBeforeDate),
        },
      },
      onCompleted: ({ updateRfpTenderDetails: res }) => {
        setFormState({
          tenderDetails: {
            ...res,
            startDate: !!res.startDate ? dayjs(res.startDate) : null,
            endDate: !!res.endDate ? dayjs(res.endDate) : null,
            emdEndDate: !!res.emdEndDate ? dayjs(res.emdEndDate) : null,
            emdNotifyBeforeDate: !!res.emdNotifyBeforeDate ? dayjs(res.emdNotifyBeforeDate) : null,
          },
        });
        toggleEditForm(false);
      },
    });
  };

  const govTenderDetails = rfpTenderDetails.isGovtTender
    ? [
        {
          label: 'EMD Number',
          value: rfpTenderDetails.emdNumber,
          type: 'STRING',
        },
        {
          label: 'EMD Amount',
          value: rfpTenderDetails.emdAmount,
          type: 'NUMBER',
        },
        {
          label: 'EMD EndDate',
          value: rfpTenderDetails.emdEndDate,
          type: 'DATE',
        },
        {
          label: 'EMD Notify Before Date',
          value: rfpTenderDetails.emdNotifyBeforeDate,
          type: 'DATE',
        },
        {
          label: 'Document',
          value: !!rfpTenderDetails.emdDocument ? (
            <Grid container direction="column" rowGap={0.6}>
              {/* {
                <Typography key={rfpTenderDetails.emdDocument.path} variant="body1">
                  <a href={rfpTenderDetails.emdDocument.path} target="_blank">
                    {rfpTenderDetails.emdDocument.name}
                  </a>
                </Typography>
              } */}
            </Grid>
          ) : undefined,
          type: 'NODE',
        },
      ]
    : [];

  return (
    <>
      <Section
        title="Tender Details"
        collapsible
        data={[
          {
            label: 'ID',
            value: rfpTenderDetails.referenceId,
            type: 'STRING',
          },
          {
            label: 'Coefficient (%)',
            value: rfpTenderDetails.coefficient,
            type: 'NUMBER',
          },
          {
            label: 'Discount (%)',
            value: rfpTenderDetails.discount,
            type: 'NUMBER',
          },
          {
            label: 'Retention (%)',
            value: rfpTenderDetails.retention,
            type: 'NUMBER',
          },
          {
            label: 'Start Date',
            value: rfpTenderDetails.startDate,
            type: 'DATE',
          },
          {
            label: 'End Date',
            value: rfpTenderDetails.endDate,
            type: 'DATE',
          },
          {
            label: 'Billing',
            value: getEnumKeyFromValue(BillingTypeEnum, rfpTenderDetails.billingType),
            type: 'STRING',
          },
          {
            label: 'Submission Mode',
            value: getEnumKeyFromValue(SubmissionModeEnum, rfpTenderDetails.submissionMode),
            type: 'STRING',
          },
          {
            label: 'Site Visited',
            value: rfpTenderDetails.siteVisited ? 'YES' : 'NO',
            type: 'STRING',
          },
          {
            label: 'Govt Tender',
            value: rfpTenderDetails.isGovtTender ? 'YES' : 'NO',
            type: 'STRING',
          },
          // @ts-ignore
          ...govTenderDetails,
        ]}
        action={<EditNoteIcon onClick={() => toggleEditForm(true)} sx={{ cursor: 'pointer' }} />}
      />
      <SimplePopup
        onClose={() => toggleEditForm(false)}
        open={showEditForm}
        title="Update Tender Details"
        fullWidth
      >
        <TenderDetailsForm
          formState={formState}
          setFormState={setFormState}
          loading={updating}
          handleSubmit={handleSubmit}
          initialData={initialData}
        />
      </SimplePopup>
    </>
  );
};

export default TenderDetailsSection;
