import { Box, Grid, IconButton, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { FC } from 'react';

import SidebarLayout from 'layouts/SidebarLayout';

import GoBackButton from 'components/GoBackButton';
import SearchBar from 'components/Inputs/Search';

import logOutOutline from 'media/icons/log-out-outline.svg';

import { logout } from 'utils/auth';

import theme from './theme.module.scss';

type SearchInputConfig =
  | {
      enable: true;
      name?: string;
      placeholder?: string;
      handleChange: (arg: string) => void;
    }
  | {
      enable: false;
      name?: string;
      placeholder?: string;
      handleChange?: (arg: string) => void;
    };

type NavbarProps = {
  actions?: any[];
  children: React.ReactNode;
  searchInputConfig?: SearchInputConfig;
  actionChildren?: React.ReactNode;
} & (
  | { title: string; goBackButtonConfig?: undefined }
  | {
      title?: undefined;
      goBackButtonConfig: {
        title: string;
        onClick?: () => void;
        hideSeparator?: boolean;
      };
    }
);

const Navbar: FC<NavbarProps> = ({
  children,
  title,
  goBackButtonConfig = {
    title: '',
    onClick: () => {},
    hideSeparator: false,
  },
  searchInputConfig = {
    enable: false,
  },
  actionChildren = null,
}) => {
  const isMobileScreen = useMediaQuery('(max-width:600px)');

  const renderContent = () => (
    <div className={theme.container}>
      <div className={theme.logoutContainer}>
        <Tooltip title="Logout">
          <IconButton onClick={() => logout()}>
            <img height="25.6px" src={logOutOutline} alt="logout" />
          </IconButton>
        </Tooltip>
      </div>
      <Grid container justifyContent="space-between" alignItems="center">
        {!!title ? (
          <Typography variant="h6" fontWeight={700} color="black">
            {title}
          </Typography>
        ) : (
          <GoBackButton
            title={goBackButtonConfig.title}
            goBackAction={goBackButtonConfig.onClick}
            hideSeparator={!!goBackButtonConfig.hideSeparator}
          />
        )}
        {!!actionChildren && <Box>{actionChildren}</Box>}
      </Grid>
      {searchInputConfig.enable && (
        <SearchBar
          name={!!searchInputConfig.name ? searchInputConfig.name : 'Search Input'}
          label={!!searchInputConfig.name ? searchInputConfig.name : 'Search'}
          placeholder={
            !!searchInputConfig.placeholder ? searchInputConfig.placeholder : 'Search Input'
          }
          onSearchInputChange={searchInputConfig.handleChange}
          style={{ marginBlock: 7, paddingTop: 9 }}
        />
      )}
    </div>
  );

  const renderDesktopView = () => (
    <Grid className={theme.desktopContainer}>
      <Grid item xs={12} container justifyContent="space-between" alignItems="center">
        {!!title ? (
          <Typography variant="h5" fontWeight={700} color="black">
            {title}
          </Typography>
        ) : (
          <GoBackButton
            title={goBackButtonConfig.title}
            goBackAction={goBackButtonConfig.onClick}
            hideSeparator={!!goBackButtonConfig.hideSeparator}
          />
        )}
        <Tooltip title="Logout">
          <IconButton onClick={() => logout()}>
            <img height="25.6px" src={logOutOutline} alt="logout" />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          columnGap: 2,
        }}
      >
        {searchInputConfig.enable && (
          <Box flexBasis={!!actionChildren ? '94%' : '100%'}>
            <SearchBar
              name={!!searchInputConfig.name ? searchInputConfig.name : 'Search Input'}
              label={!!searchInputConfig.name ? searchInputConfig.name : 'Search'}
              placeholder={
                !!searchInputConfig.placeholder ? searchInputConfig.placeholder : 'Search Input'
              }
              onSearchInputChange={searchInputConfig.handleChange}
              style={{ marginBlock: 7, paddingTop: 9 }}
            />
          </Box>
        )}
        {!!actionChildren && <Box flexBasis="5%">{actionChildren}</Box>}
      </Grid>
    </Grid>
  );

  return (
    <>
      {isMobileScreen ? (
        <Box>
          {renderContent()}
          <SidebarLayout>{children}</SidebarLayout>
        </Box>
      ) : (
        <SidebarLayout>
          {renderDesktopView()}
          <Box mt={2}>{children}</Box>
        </SidebarLayout>
      )}
    </>
  );
};

export default Navbar;
