import { Grid } from '@mui/material';
import React from 'react';
import { LeadScopeEnum, LeadSegmentEnum, Project } from 'types';

import Card from 'components/Card';

import { getEnumKeyFromValue } from 'utils/transformFn';

const ProjectTemplate: React.FC<{ projects: Project[] }> = ({ projects }) => {
  return (
    <Grid container columns={3} rowSpacing={3} columnSpacing={5} alignItems="center" mb={5}>
      {projects.map(project => (
        <Grid item key={project._id} width={'100%'} md={1.5} lg={1}>
          <Card
            headers={[project.name, project.referenceId, project.status]}
            data={[
              {
                label: 'Lead',
                value: project.lead.name,
              },
              {
                label: 'Scope',
                value: getEnumKeyFromValue(LeadScopeEnum, project.lead.scope),
              },
              {
                label: 'Segment',
                value: getEnumKeyFromValue(LeadSegmentEnum, project.lead.segment),
              },
            ]}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ProjectTemplate;
