import EditNoteIcon from '@mui/icons-material/EditNote';
import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { CostSubTypeEnum, CostTypeEnum, RfpTenderCommercials } from 'types';

import { SimplePopup } from 'components/Popup';
import UpdateCommercialsForm from 'components/RFPs/Forms/UpdateCommercialsForm';
import Section from 'components/Section';

import { convertAmountToIndianUnits } from 'utils/formatHelper';
import { getEnumKeyFromValue } from 'utils/transformFn';

const TenderCommercialSection: React.FC<{ rfpTenderCommercials: RfpTenderCommercials }> = ({
  rfpTenderCommercials,
}) => {
  const [showEditForm, toggleEditForm] = useState(false);

  return (
    <>
      <Section
        title="Commercial Details"
        collapsible
        data={[
          {
            label: 'ID',
            value: rfpTenderCommercials.referenceId,
            type: 'STRING',
          },
          {
            label: 'Cost Type',
            value: getEnumKeyFromValue(CostTypeEnum, rfpTenderCommercials.costType),
            type: 'NUMBER',
          },
          {
            label: 'Cost Sub Type',
            value: getEnumKeyFromValue(CostSubTypeEnum, rfpTenderCommercials.costSubType),
            type: 'STRING',
          },
          {
            label: 'Cost Value',
            value: convertAmountToIndianUnits(rfpTenderCommercials.costValue),
            type: 'NUMBER',
          },
          {
            label: 'Prcie Value',
            value: convertAmountToIndianUnits(rfpTenderCommercials.priceValue),
            type: 'NUMBER',
          },
          {
            label: 'Margin',
            value: convertAmountToIndianUnits(rfpTenderCommercials.marginValue),
            type: 'NUMBER',
          },
        ]}
        action={<EditNoteIcon onClick={() => toggleEditForm(true)} sx={{ cursor: 'pointer' }} />}
      />
      <SimplePopup
        onClose={() => toggleEditForm(false)}
        open={showEditForm}
        title="Update Commercials Details"
        fullWidth
      >
        <Grid px={2} pb={3} pt={1} alignItems="center">
          <UpdateCommercialsForm
            cb={() => toggleEditForm(false)}
            commercial={rfpTenderCommercials}
          />
        </Grid>
      </SimplePopup>
    </>
  );
};

export default TenderCommercialSection;
