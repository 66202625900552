import { gql, useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RFP } from 'types';

import Navbar from 'components/Navbar';
import RFPPOAndCommercialsDetailForm from 'components/RFPs/Forms/RFPPOAndCommercialsDetailForm';
import TenderDetailsForm from 'components/RFPs/Forms/TenderDetailsForm';

import { removeEmptyFields } from 'utils/common';
import { formatDate } from 'utils/transformFn';

const CREATE_RFP_MUTATION = gql`
  mutation CreateConsolidatedRfp($input: CreateConsolidatedRfpInput!) {
    createConsolidatedRfp(input: $input) {
      _id
      referenceId
    }
  }
`;

type CreateRFPMutationResponse = {
  createConsolidatedRfp: RFP;
};

type CreateRFPMutationVariables = {
  input: {
    pq: string;
    tenderDetails: {
      billingType: string;
      submissionMode: string;
      siteVisited: boolean;
      isGovtTender: boolean;
      coefficient?: Number;
      discount?: Number;
      retention?: Number;
      emdNumber?: string;
      startDate?: string;
      endDate?: string;
      emdEndDate?: string;
      emdNotifyBeforeDate?: string;
      emdDocument?: File;
      emdAmount?: Number;
    };
    tenderCommercials: {
      costType: string;
      costSubType: string;
      costValue: Number;
      marginValue: Number;
      priceValue: Number;
    };
    po: {
      poOrLOIReferenceNumber: string;
      poOrLOIDate?: string;
    };
  };
};

const CreateRFPPage = () => {
  const [currPage, setCurrPage] = useState(0);
  const [formState, setFormState] = useState<Record<string, any>>({
    tenderDetails: {
      siteVisited: null,
      isGovtTender: null,
    },
  });

  const navigate = useNavigate();

  const [createRFP, { loading: creatingRFP }] = useMutation<
    CreateRFPMutationResponse,
    CreateRFPMutationVariables
  >(CREATE_RFP_MUTATION);

  const handleNext = () => {
    setCurrPage(1);
  };

  const handleSubmit = () => {
    let file: File | undefined;
    if (!!formState.tenderDetails.emdDocument) file = formState.tenderDetails.emdDocument;

    const refinedData: Record<string, any> = removeEmptyFields({ ...formState });

    const td = refinedData.tenderDetails;
    const tc = refinedData.tenderCommercials;

    if (!td.isGovtTender) {
      delete td.emdAmount;
      delete td.emdDocument;
      delete td.emdEndDate;
      delete td.emdNotifyBeforeDate;
      delete td.emdNumber;
    }

    createRFP({
      variables: {
        input: {
          pq: refinedData.pq,
          po: {
            poOrLOIDate: formatDate(formState.po.poOrLOIDate),
            poOrLOIReferenceNumber: refinedData.po.poOrLOIReferenceNumber,
          },
          tenderCommercials: {
            ...tc,
            costValue: Number(tc.costValue),
            marginValue: Number(tc.marginValue),
            priceValue: Number(tc.priceValue),
          },
          tenderDetails: {
            ...td,
            siteVisited: td.siteVisited,
            isGovtTender: td.isGovtTender,
            emdAmount: !!td.emdAmount ? Number(td.emdAmount) : undefined,
            emdDocument: file,
            startDate: formatDate(td.startDate),
            endDate: formatDate(td.endDate),
            emdEndDate: formatDate(td.emdEndDate),
            emdNotifyBeforeDate: formatDate(td.emdNotifyBeforeDate),
          },
        },
      },
      onCompleted: res => navigate(`/rfps/${res.createConsolidatedRfp._id}`),
    });
  };

  return (
    <Navbar
      goBackButtonConfig={{
        title: currPage === 0 ? 'Create RFP' : 'Tender Details',
        onClick: currPage === 1 ? () => setCurrPage(0) : undefined,
      }}
    >
      <Grid item container direction="column" xs={12} md={11} lg={9} xl={6} mt={2}>
        {currPage === 0 && (
          <RFPPOAndCommercialsDetailForm
            formState={formState}
            setFormState={setFormState}
            handleNext={handleNext}
          />
        )}
        {currPage === 1 && (
          <TenderDetailsForm
            formState={formState}
            setFormState={setFormState}
            handleSubmit={handleSubmit}
            loading={creatingRFP}
          />
        )}
      </Grid>
    </Navbar>
  );
};

export default CreateRFPPage;
