import { Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { LeadScopeEnum, PQ, PqApprovalStatusEnum, PqPriorityEnum, PqStatusEnum } from 'types';

import { SimplePopup } from 'components/Popup';
import Section from 'components/Section';

import { getEnumKeyFromValue } from 'utils/transformFn';

import DocumentActionForm from './DocumentActionForm';
import UpdatePQForm from './UpdatePQForm';

const PQSection: React.FC<{ pq: PQ }> = ({ pq }) => {
  const [enableFormEdit, toggleFormEdit] = useState(false);
  const [enableDocumentActionForm, toggleDocumentActionForm] = useState(false);

  const shouldDisableEdit = pq.status === PqStatusEnum.SUBMITTED;
  const shouldDisableSubmit =
    pq.approvalStatus !== PqApprovalStatusEnum.PENDING || pq.status === PqStatusEnum.SUBMITTED;

  const pqActions = [
    { title: 'Edit', onClick: () => toggleFormEdit(true), disabled: shouldDisableEdit },
    {
      title: 'Submit',
      onClick: () => toggleDocumentActionForm(true),
      disabled: shouldDisableSubmit,
    },
  ];

  return (
    <Grid pt={2} container direction="column" rowGap={1.5}>
      <Section
        title="PQ Details"
        collapsible
        data={[
          {
            label: 'ID',
            value: pq.referenceId,
            type: 'STRING',
          },
          {
            label: 'Lead',
            value: pq.lead?.name,
            type: 'LINK',
            navigateTo: `/leads/${pq.lead._id}`,
          },
          {
            label: 'Scope',
            value:
              pq.lead?.scope !== undefined && getEnumKeyFromValue(LeadScopeEnum, pq.lead.scope),
            type: 'STRING',
          },
          {
            label: 'Priority',
            value: getEnumKeyFromValue(PqPriorityEnum, pq.priority),
            type: 'STRING',
          },
          {
            label: 'Status',
            value: getEnumKeyFromValue(PqStatusEnum, pq.status),
            type: 'STRING',
          },
          {
            label: 'Approval Status',
            value: getEnumKeyFromValue(PqApprovalStatusEnum, pq.approvalStatus),
            type: 'STRING',
          },
          {
            label: 'Creation Date',
            value: pq.creationDate,
            type: 'DATE',
          },
          {
            label: 'Submitted Date',
            value: pq.submitionDate,
            type: 'DATE',
          },
          {
            label: 'Documents',
            value: !!pq.documents.length ? (
              <Grid container direction="column" rowGap={0.6}>
                {pq.documents.map(doc => (
                  <Typography key={doc._id} variant="body1">
                    <a href={doc.documentMaster.path} target="_blank">
                      {doc.documentMaster.name}
                    </a>
                  </Typography>
                ))}
              </Grid>
            ) : undefined,
            type: 'NODE',
          },
        ]}
        action={pqActions}
      />
      <SimplePopup
        onClose={() => toggleFormEdit(false)}
        open={enableFormEdit}
        title="Update PQ"
        fullWidth
      >
        <Grid px={1.5} py={1.5}>
          <UpdatePQForm cb={() => toggleFormEdit(false)} pq={pq} />
        </Grid>
      </SimplePopup>
      <SimplePopup
        onClose={() => toggleDocumentActionForm(false)}
        open={enableDocumentActionForm}
        title="Submit PQ"
        fullWidth
      >
        <DocumentActionForm cb={() => toggleDocumentActionForm(false)} pqId={pq._id} />
      </SimplePopup>
    </Grid>
  );
};

export default PQSection;
