import { LoadingButton } from '@mui/lab';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  useMediaQuery,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from 'react';
import { BillingTypeEnum, SubmissionModeEnum } from 'types';

import Fieldset from 'components/Fieldset';
import { getUpdatedFields } from 'components/FormPanel';
import { Select } from 'components/Inputs/Select';
import TextField from 'components/Inputs/TextField';
import UploadFile from 'components/Inputs/UploadFile';

const TenderDetailsForm: React.FC<{
  formState: Record<string, any>;
  setFormState: (arg: any) => void;
  handleSubmit: () => void;
  loading: boolean;
  initialData?: Record<string, any>;
}> = ({ loading, handleSubmit, formState, setFormState, initialData }) => {
  const [errors, setErrors] = useState<Record<string, string | null>>({});

  const isMobileScreen = useMediaQuery('(max-width:600px)');

  const handleTenderChange = (fieldName: string, value: any) => {
    if (!!errors.siteVisited || !!errors.isGovtTender) setErrors({});

    setFormState(prev => ({
      ...prev,
      tenderDetails: {
        ...(prev.tenderDetails ?? {}),
        [fieldName]: value,
      },
    }));
  };

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      formState.tenderDetails?.siteVisited !== null &&
      formState.tenderDetails?.isGovtTender !== null
    ) {
      handleSubmit();
      return;
    }

    const errs: Record<string, string> = {};
    if (formState.tenderDetails.siteVisited === null) errs.siteVisited = 'This field is required';
    if (formState.tenderDetails.isGovtTender === null) errs.isGovtTender = 'This field is required';

    if (!!Object.keys(errs).length) {
      setErrors(errs);
    }
  };

  return (
    <form onSubmit={submitHandler}>
      <Grid container columnSpacing={1.5} rowGap={2} px={2} pb={3} pt={1} alignItems="center">
        <Grid item xs={6}>
          <Select
            value={formState.tenderDetails?.billingType ?? ''}
            options={Object.keys(BillingTypeEnum).map(k => ({
              label: k,
              value: BillingTypeEnum[k],
            }))}
            onChange={val => handleTenderChange('billingType', val)}
            label="Billing Type"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            value={formState.tenderDetails?.submissionMode ?? ''}
            options={Object.keys(SubmissionModeEnum).map(k => ({
              label: k,
              value: SubmissionModeEnum[k],
            }))}
            onChange={val => handleTenderChange('submissionMode', val)}
            label="Submission Mode"
            required
          />
        </Grid>
        <Grid item xs={6} mt={-0.7}>
          <Fieldset label="Site Visited * " variant="small" error={!!errors.siteVisited}>
            <FormControl required error={!!errors.siteVisited}>
              <RadioGroup
                row
                aria-required
                name="siteVisited"
                value={formState.tenderDetails?.siteVisited ?? null}
                onChange={e => handleTenderChange('siteVisited', e.target.value === 'true')}
              >
                <FormControlLabel value={true} control={<Radio size="small" />} label="Yes" />
                <FormControlLabel value={false} control={<Radio size="small" />} label="No" />
              </RadioGroup>
            </FormControl>
          </Fieldset>
          {!!errors.siteVisited && <FormHelperText error>{errors.siteVisited}</FormHelperText>}
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Coefficient (%)"
            value={formState.tenderDetails?.coefficient ?? ''}
            onChange={e => {
              let value: string | number = parseFloat(e.target.value);
              if (e.target.value !== '') {
                if (value < 0) value = 0;
                if (value > 100) value = 100;
              } else {
                value = '';
              }
              handleTenderChange('coefficient', value);
            }}
            type="number"
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Discount (%)"
            value={formState.tenderDetails?.discount ?? ''}
            onChange={e => {
              let value: string | number = parseFloat(e.target.value);
              if (e.target.value !== '') {
                if (value < 0) value = 0;
                if (value > 100) value = 100;
              } else {
                value = '';
              }
              handleTenderChange('discount', value);
            }}
            type="number"
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Retention (%)"
            value={formState.tenderDetails?.retention ?? ''}
            onChange={e => {
              let value: string | number = parseFloat(e.target.value);
              if (e.target.value !== '') {
                if (value < 0) value = 0;
                if (value > 100) value = 100;
              } else {
                value = '';
              }
              handleTenderChange('retention', value);
            }}
            type="number"
          />
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={formState.tenderDetails?.startDate ?? null}
                format="DD/MM/YYYY"
                onChange={val => handleTenderChange('startDate', val)}
                label="Start Date"
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    fullWidth: true,
                  },
                }}
                maxDate={formState.tenderDetails?.endDate}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={formState.tenderDetails?.endDate ?? null}
                onChange={val => handleTenderChange('endDate', val)}
                format="DD/MM/YYYY"
                label="End Date"
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    fullWidth: true,
                  },
                }}
                minDate={formState.tenderDetails?.startDate}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>

        <Grid item xs={12} mt={-0.7}>
          <Fieldset label="is Gov Tender * " variant="small" error={!!errors.isGovtTender}>
            <FormControl required error={!!errors.siteVisited}>
              <RadioGroup
                row
                aria-required
                name="is Gov Tender"
                value={formState.tenderDetails.isGovtTender ?? null}
                onChange={e => handleTenderChange('isGovtTender', e.target.value === 'true')}
              >
                <FormControlLabel value={true} control={<Radio size="small" />} label="Yes" />
                <FormControlLabel value={false} control={<Radio size="small" />} label="No" />
              </RadioGroup>
            </FormControl>
          </Fieldset>
          {!!errors.isGovtTender && <FormHelperText error>{errors.isGovtTender}</FormHelperText>}
        </Grid>
        {!!formState.tenderDetails?.isGovtTender && (
          <>
            <Grid item xs={6}>
              <TextField
                label="EMD Number"
                type="string"
                value={formState.tenderDetails?.emdNumber ?? ''}
                onChange={e => {
                  handleTenderChange('emdNumber', e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="EMD Amount"
                type="number"
                value={formState.tenderDetails?.emdAmount ?? ''}
                onChange={e => {
                  handleTenderChange('emdAmount', e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <b color="lightGray">₹</b>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={formState.tenderDetails?.emdEndDate ?? null}
                    onChange={val => {
                      handleTenderChange('emdEndDate', val);
                      handleTenderChange('emdNotifyBeforeDate', null);
                    }}
                    format="DD/MM/YYYY"
                    label="EMD End Date"
                    slotProps={{
                      textField: {
                        variant: 'outlined',
                        fullWidth: true,
                      },
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={formState.tenderDetails?.emdNotifyBeforeDate ?? null}
                    onChange={val => handleTenderChange('emdNotifyBeforeDate', val)}
                    disabled={!!!formState.tenderDetails?.emdEndDate}
                    format="DD/MM/YYYY"
                    label="EMD Notify Before Date"
                    slotProps={{
                      textField: {
                        variant: 'outlined',
                        fullWidth: true,
                      },
                    }}
                    maxDate={formState.tenderDetails?.emdEndDate}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>

            <Grid item xs={isMobileScreen ? 12 : 6}>
              <FormControl fullWidth>
                <UploadFile
                  values={formState.tenderDetails?.emdDocument}
                  onChange={(file: FileList) => handleTenderChange('emdDocument', file)}
                  accept=".pdf,.xlsx,.csv"
                  label="EMD Document"
                />
              </FormControl>
            </Grid>
          </>
        )}
        <Grid item xs={12} container justifyContent="end">
          <LoadingButton
            fullWidth={isMobileScreen}
            loading={loading}
            disabled={
              !!initialData
                ? !!!Object.keys(getUpdatedFields(initialData ?? {}, formState.tenderDetails ?? {}))
                    .length
                : undefined
            }
            type="submit"
            variant="contained"
          >
            {!!initialData ? 'Update' : 'Create'}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default TenderDetailsForm;
