import { gql } from '@apollo/client';
import { Folder, FolderTypeEnum } from 'types';

export const GET_FOLDERS_QUERY_FOR_AC = gql`
  query GetFolders($type: FolderTypeEnum!, $projectOrLeadId: String, $parentFolder: String) {
    getFolders(type: $type, projectOrLeadId: $projectOrLeadId, parentFolder: $parentFolder) {
      _id
      referenceId
      name
      type
    }
  }
`;

export const GET_FOLDERS_QUERY_FOR_LV = gql`
  query GetFolders(
    $type: FolderTypeEnum!
    $projectOrLeadId: String
    $parentFolder: String
    $searchTerm: String
  ) {
    getFolders(
      type: $type
      projectOrLeadId: $projectOrLeadId
      parentFolder: $parentFolder
      searchTerm: $searchTerm
    ) {
      title
      folders {
        _id
        referenceId
        name
        type
        fileCount
        parentFolder {
          _id
          name
        }
        isStandardFolder
      }
    }
  }
`;

export type GetFoldersQueryResponse = {
  getFolders: {
    title: string;
    folders: Folder[];
  };
};

export type GetFoldersQueryVariables = {
  type: FolderTypeEnum;
  projectOrLeadId?: string;
  searchTerm?: string;
  parentFolder?: string;
};
