import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { PqApprovalStatusEnum } from 'types';

import TextField from 'components/Inputs/TextField';

import { removeEmptyFields } from 'utils/common';

const DocumentActionForm: React.FC<{ pqId: string; cb: () => void }> = ({ pqId, cb }) => {
  const [formState, setFormState] = useState<Record<string, any>>({});

  const handleChange = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleSubmit = (type: PqApprovalStatusEnum) => {
    const refinedData = removeEmptyFields({ ...formState });

    console.log({ comment: refinedData.comment, approvalStatus: type });
  };

  const updating = false;

  return (
    <Grid container px={1.5} py={1.5} rowGap={2} columnSpacing={1.5}>
      <Grid item xs={12}>
        <TextField
          required
          label="Comment"
          value={formState.comment}
          onChange={e => handleChange('comment', e.target.value)}
          type="text"
        />
      </Grid>
      <Grid item xs={6}>
        <LoadingButton
          loading={false}
          onClick={() => handleSubmit(PqApprovalStatusEnum.APPROVED)}
          fullWidth
          variant="contained"
          disabled={!!!formState.comment || updating}
        >
          Approve
        </LoadingButton>
      </Grid>
      <Grid item xs={6}>
        <LoadingButton
          fullWidth
          variant="contained"
          loading={false}
          onClick={() => handleSubmit(PqApprovalStatusEnum.REJECTED)}
          color="error"
          disabled={!!!formState.comment || updating}
        >
          Reject
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default DocumentActionForm;
