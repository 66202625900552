import { gql, useLazyQuery, useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import React from 'react';
import { DocumentMaster, Lead, PQ, PqPriorityEnum } from 'types';

import { FormInput, FormPanel, getUpdatedFields } from 'components/FormPanel';

const GET_LEADS_QUERY = gql`
  query GetLeads($filter: LeadFilter) {
    getLeads(filter: $filter) {
      _id
      referenceId
      name
    }
  }
`;

type GetLeadsQueryResponse = {
  getLeads: Lead[];
};

const UPDATE_PQ_MUTATION = gql`
  mutation UpdatePQ($input: UpdatePQInput!) {
    updatePQ(input: $input) {
      _id
      referenceId
      lead {
        _id
        name
        referenceId
      }
      priority
      status
      creationDate
      submitionDate
      approvalStatus
      documents {
        _id
        referenceId
        type
        typeId
        version
        path
        comments
      }
    }
  }
`;

type UpdatePQMutationResponse = {
  updatePQ: PQ;
};
type UpdatePQMutationVariables = {
  input: {
    _id: string;
    lead?: string;
    priority?: PqPriorityEnum;
    creationDate?: string;
    documents?: DocumentMaster[];
  };
};

const UpdatePQForm: React.FC<{ pq: PQ; cb: (pq: PQ) => void }> = ({ cb, pq }) => {
  const [updatePQ, { loading: updatingPQ }] = useMutation<
    UpdatePQMutationResponse,
    UpdatePQMutationVariables
  >(UPDATE_PQ_MUTATION);

  const [getLeads, { loading: loadingLeads, data: leads }] =
    useLazyQuery<GetLeadsQueryResponse>(GET_LEADS_QUERY);

  const handleSubmit = (data: Record<string, any>) => {
    const files = data.documents ?? [];
    const creationDate = data.creationDate;
    const refinedData = getUpdatedFields(pq, data);

    refinedData.documents = files;
    refinedData.creationDate = creationDate;

    updatePQ({
      variables: {
        input: {
          _id: pq._id,
          ...refinedData,
        },
      },
      onCompleted: newPQ => cb(newPQ.updatePQ),
    });
  };

  return (
    <FormPanel
      error={null}
      loading={updatingPQ}
      onSubmit={handleSubmit}
      submitButtonLabel="Update PQ"
    >
      <FormInput
        fieldName="lead"
        fullWidth
        label="Lead"
        type="auto_complete_with_fetch"
        defaultValue={pq.lead}
        autoCompleteConfig={{
          fetchOptionsFn: getLeads,
          loading: loadingLeads,
          options: leads?.getLeads ?? [],
        }}
        validators={{
          required: true,
        }}
      />
      <FormInput
        fieldName="priority"
        defaultValue={pq.priority}
        label="Priority"
        type="select"
        options={Object.values(PqPriorityEnum).map(o => ({ label: o, value: o }))}
      />
      <FormInput
        fieldName="creationDate"
        label="PQ Creation Date"
        type="date"
        defaultValue={!!pq.creationDate ? dayjs(pq.creationDate) : null}
        validators={{
          dependsOn: ['document'],
        }}
      />
      <FormInput
        fieldName="documents"
        label="PQ Document"
        type="upload_file"
        accept=".pdf, .xlsx, .csv"
        fullWidth
        multiple
        defaultValue={pq.documents}
        dateConfig={{
          disablePast: true,
        }}
      />
    </FormPanel>
  );
};

export default UpdatePQForm;
