import { NetworkStatus, gql, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PQ } from 'types';

import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import PQsTemplate from 'components/PQs';

const GET_PQ_QUERY = gql`
  query GetPQs {
    getPQs {
      _id
      approvalStatus
      creationDate
      priority
      referenceId
      status
      submitionDate
      referenceId
      lead {
        _id
        name
        scope
      }
    }
  }
`;
type GetPqQueryResponse = {
  getPQs: PQ[];
};

type GetPqQueryVariables = {
  filter: {
    searchTerm?: string;
  };
};

const PQsPage = () => {
  const [filters, setFilters] = useState<Record<string, any>>({});
  const {
    data: pqs,
    networkStatus,
    refetch: refetchPqs,
  } = useQuery<GetPqQueryResponse, GetPqQueryVariables>(GET_PQ_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const navigate = useNavigate();

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
    refetchPqs({
      filter: newFilters,
    });
  };

  const loadingPQs =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!pqs;

  return (
    <Navbar
      title="PQs"
      actionChildren={
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={() => navigate('create-pq')}
        >
          <AddIcon fontSize="small" />
        </Button>
      }
      searchInputConfig={{
        enable: true,
        name: 'PQs search',
        placeholder: 'ID / Name',
        handleChange: searchFilter =>
          applyFilters({
            ...filters,
            searchTerm: !!searchFilter ? searchFilter : null,
          }),
      }}
    >
      {loadingPQs ? <LoadingIndicator size="1.6rem" /> : <PQsTemplate pqs={pqs.getPQs} />}
    </Navbar>
  );
};

export default PQsPage;
