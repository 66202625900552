import { gql, useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { PQ } from 'types';

import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import PQSection from 'components/PQs/PQSection';

const PQ_QUERY = gql`
  query GetPQById($id: ID!) {
    getPQById(_id: $id) {
      _id
      approvalStatus
      creationDate
      documents {
        _id
        referenceId
        type
        typeId
        documentMaster {
          _id
          type
          name
          version
          comments
          financialYear
          path
        }
      }
      lead {
        _id
        name
        scope
      }
      priority
      referenceId
      status
      submitionDate
      referenceId
    }
  }
`;

type PqQueryResponse = {
  getPQById: PQ;
};

type PqQueryVariables = {
  id: string;
};

const PQDetailsPage = () => {
  const { pqId = '' } = useParams<{ pqId: string }>();

  const { data: pq, loading: loadingPQ } = useQuery<PqQueryResponse, PqQueryVariables>(PQ_QUERY, {
    variables: {
      id: pqId,
    },
  });

  const loading = loadingPQ || !!!pq;

  return (
    <Navbar
      goBackButtonConfig={{
        title: 'PQ Details',
      }}
    >
      {loading ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <Grid item container direction="column" xs={12} md={11} lg={9} xl={7} mb={5} rowGap={2.5}>
          <PQSection pq={pq.getPQById} />
        </Grid>
      )}
    </Navbar>
  );
};

export default PQDetailsPage;
