import {
  CardContent,
  CardProps,
  Grid,
  Card as MuiCard,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import React from 'react';

type DataType = 'clamp' | 'string';

type CardType = {
  headers: [string, string, string] | [string, string];
  data?: { label: string; value: any; type?: DataType }[];
  children?: React.ReactNode;
} & CardProps;

const Card: React.FC<CardType> = ({ headers, data = [], children = null, ...props }) => {
  return (
    <MuiCard variant="elevation" elevation={4} sx={{ cursor: 'pointer' }} {...props}>
      {/* header */}
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        bgcolor={theme => theme.palette.secondary.light}
        color="white"
        sx={{
          py: 0.2,
          px: 2,
        }}
      >
        <Grid item>
          <Typography variant="subtitle1" fontWeight={600}>
            {headers[0]}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" fontWeight={600}>
            {headers[1]}
          </Typography>
        </Grid>
        {headers.length === 3 && (
          <Grid item>
            <Typography variant="body1" fontWeight={600}>
              {headers[2]}
            </Typography>
          </Grid>
        )}
      </Grid>
      <CardContent sx={{ pt: 0 }}>
        {/* content */}
        <Grid mt={1.5} container columns={3} alignItems="end">
          <Grid item container direction="column" rowGap={0.5}>
            {data.map(d => (
              <CardLineItem key={d.label} label={d.label} value={d.value} type={d.type} />
            ))}
          </Grid>
          {children}
        </Grid>
      </CardContent>
    </MuiCard>
  );
};

const CardLineItem = ({
  label,
  value,
  type = 'string',
}: {
  label: string;
  value: any;
  type?: DataType;
}) => {
  const customCss = (): SxProps<Theme> => {
    switch (type) {
      case 'string':
        return {};
      case 'clamp':
        return {
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        };
      default:
        return {};
    }
  };

  return (
    <Grid container columns={3} alignItems="center">
      <Grid item width={'25%'} minWidth="60px">
        <Typography variant="caption" fontWeight={500} color="darkslategray">
          {label}
        </Typography>
      </Grid>
      <Grid item width="75%">
        <Typography variant="body1" sx={customCss()} fontWeight={600}>
          {!!value ? value : <i>None</i>}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Card;
