import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import { GET_LEADS_QUERY, GetLeadsQueryResponse } from 'graphql/query/lead';
import { useNavigate } from 'react-router-dom';
import { PQ, PqPriorityEnum } from 'types';

import { FormInput, FormPanel } from 'components/FormPanel';
import Navbar from 'components/Navbar';

const CREATE_PQ_MUTATION = gql`
  mutation CreatePQ($input: CreatePQInput!) {
    createPQ(input: $input) {
      _id
      referenceId
      lead {
        _id
        name
        referenceId
      }
      priority
      status
      creationDate
      submitionDate
      approvalStatus
      documents {
        _id
        referenceId
        type
        typeId
        version
        name
        path
        comments
      }
    }
  }
`;

type CreatePQMutationResponse = {
  createPQ: PQ;
};

type CreatePqMutationVariables = {
  input: {
    lead: string;
    priority: PqPriorityEnum;
    creationDate?: string;
    documents?: string;
  };
};

const PQCreatePage = () => {
  const [getLeads, { loading: loadingLeads, data: leads }] =
    useLazyQuery<GetLeadsQueryResponse>(GET_LEADS_QUERY);

  const [createPq, { loading: creatingPq }] = useMutation<
    CreatePQMutationResponse,
    CreatePqMutationVariables
  >(CREATE_PQ_MUTATION);

  const navigate = useNavigate();

  const handleSubmit = (data: Record<string, any>) => {
    data.lead = data.lead._id;

    createPq({
      variables: {
        input: data as any,
      },
      onCompleted: res => navigate(`/pqs/${res.createPQ._id}`),
    });
  };

  return (
    <Navbar
      goBackButtonConfig={{
        title: 'Create PQ',
      }}
    >
      <Grid container columns={12} mt={2}>
        <FormPanel
          error={null}
          loading={creatingPq}
          onSubmit={handleSubmit}
          submitButtonLabel="Create PQ"
        >
          <FormInput
            fieldName="lead"
            fullWidth
            label="Lead"
            type="auto_complete_with_fetch"
            autoCompleteConfig={{
              fetchOptionsFn: getLeads,
              loading: loadingLeads,
              options: leads?.getLeads ?? [],
            }}
            validators={{
              required: true,
            }}
          />
          <FormInput
            fieldName="priority"
            defaultValue={PqPriorityEnum.LOW}
            label="Priority"
            type="select"
            options={Object.values(PqPriorityEnum).map(o => ({ label: o, value: o }))}
          />
          <FormInput
            fieldName="creationDate"
            label="PQ Creation Date"
            type="date"
            dateConfig={{
              disablePast: true,
            }}
            validators={{
              dependsOn: ['document'],
            }}
          />
          <FormInput
            fieldName="documents"
            label="PQ Document"
            type="upload_file"
            accept=".pdf, .xlsx, .csv"
            multiple
            fullWidth
            dateConfig={{
              disablePast: true,
            }}
          />
        </FormPanel>
      </Grid>
    </Navbar>
  );
};

export default PQCreatePage;
