import { NetworkStatus, gql, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Followup } from 'types';

import FollowupTemplate from 'components/Followups';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';

const GET_FOLLOWUPS_QUERY = gql`
  query GetFollowups {
    getFollowups {
      _id
      referenceId
      comments
      followupDate
      followupType
      lead {
        name
        probability
        segment
      }
      leadStatus
    }
  }
`;
type GetFollowupQueryResponse = {
  getFollowups: Followup[];
};

type GetFollowupQueryVariables = {
  filter: {
    searchTerm?: string;
  };
};

const FollowupsPage = () => {
  const [filters, setFilters] = useState<Record<string, any>>({});
  const navigate = useNavigate();

  const {
    data: followups,
    networkStatus,
    refetch: refetchFollowups,
  } = useQuery<GetFollowupQueryResponse, GetFollowupQueryVariables>(GET_FOLLOWUPS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const loadingFollowups =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!followups;

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
    refetchFollowups({
      filter: newFilters,
    });
  };

  return (
    <Navbar
      title="Events"
      searchInputConfig={{
        enable: true,
        name: 'Search',
        placeholder: 'Search Events',
        handleChange: searchFilter =>
          applyFilters({
            ...filters,
            searchTerm: !!searchFilter ? searchFilter : null,
          }),
      }}
      actionChildren={
        <Button
          variant="contained"
          size="small"
          onClick={() => navigate('create-followup')}
          color="secondary"
        >
          <AddIcon fontSize="small" />
        </Button>
      }
    >
      {loadingFollowups ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <FollowupTemplate followups={followups.getFollowups} />
      )}
    </Navbar>
  );
};

export default FollowupsPage;
