import { gql, useLazyQuery } from '@apollo/client';
import { Button, Divider, FormControl, Grid, InputAdornment, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CostSubTypeEnum, CostTypeEnum, PQ } from 'types';

import AutocompleteWithFetch from 'components/FormPanel/AutoCompleteWithFetch';
import { Select } from 'components/Inputs/Select';
import TextField from 'components/Inputs/TextField';

const GET_PQS_QUERY = gql`
  query GetPQs {
    getPQs {
      _id
      referenceId
      lead {
        name
      }
    }
  }
`;

type GetPQsQueryResponse = {
  getPQs: PQ[];
};

const RFPPOAndCommercialsDetailForm: React.FC<{
  formState: Record<string, any>;
  setFormState: (arg: any) => void;
  handleNext: () => void;
}> = ({ handleNext, formState, setFormState }) => {
  const [getPQs, { loading: loadingPQs, data: pqs }] =
    useLazyQuery<GetPQsQueryResponse>(GET_PQS_QUERY);

  const handleCommercialsChange = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      tenderCommercials: {
        ...(prev.tenderCommercials ?? {}),
        [fieldName]: value,
      },
    }));
  };

  const handlePOChange = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      po: {
        ...(prev.po ?? {}),
        [fieldName]: value,
      },
    }));
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleNext();
      }}
    >
      <Grid container columnSpacing={1.5} rowGap={2} px={2} pb={3} pt={1}>
        <Grid item xs={12}>
          <AutocompleteWithFetch
            label="PQ"
            value={formState.pq ?? ''}
            fetch={getPQs}
            loading={loadingPQs}
            required
            handleChange={val =>
              setFormState(prev => ({
                ...prev,
                pq: val,
              }))
            }
            options={
              !!pqs?.getPQs
                ? pqs.getPQs.map(pq => ({
                    referenceId: pq.referenceId,
                    _id: pq._id,
                    name: pq.lead.name,
                  }))
                : []
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Divider>
            <Typography variant="overline" fontWeight={600}>
              Tender Commercial Details
            </Typography>
          </Divider>
        </Grid>

        <Grid item xs={6}>
          <Select
            value={formState.tenderCommercials?.costType ?? ''}
            options={Object.keys(CostTypeEnum).map(k => ({ label: k, value: CostTypeEnum[k] }))}
            onChange={val => handleCommercialsChange('costType', val)}
            label="Cost Type"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            value={formState.tenderCommercials?.costSubType ?? ''}
            options={Object.keys(CostSubTypeEnum).map(k => ({
              label: k,
              value: CostSubTypeEnum[k],
            }))}
            onChange={val => handleCommercialsChange('costSubType', val)}
            label="Cost Sub Type"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={formState.tenderCommercials?.costValue ?? ''}
            onChange={e => handleCommercialsChange('costValue', e.target.value)}
            required
            label="Cost Value"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <b color="lightGray">₹</b>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={formState.tenderCommercials?.priceValue ?? ''}
            onChange={e => handleCommercialsChange('priceValue', e.target.value)}
            required
            label="Price Value"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <b color="lightGray">₹</b>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={formState.tenderCommercials?.marginValue ?? ''}
            onChange={e => handleCommercialsChange('marginValue', e.target.value)}
            required
            label="Margin Value"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <b color="lightGray">₹</b>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider>
            <Typography variant="overline" fontWeight={600}>
              PO Details
            </Typography>
          </Divider>
        </Grid>

        <Grid item xs={6}>
          <TextField
            type="string"
            value={formState.po?.poOrLOIReferenceNumber ?? ''}
            onChange={e => handlePOChange('poOrLOIReferenceNumber', e.target.value)}
            required
            label="PO or LOI Reference Number"
          />
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={formState.po?.poOrLOIDate ?? null}
                format="DD/MM/YYYY"
                onChange={val => handlePOChange('poOrLOIDate', val)}
                label="PO or LOI Date"
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    fullWidth: true,
                  },
                }}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>

        <Grid item xs={12} alignSelf="center" container justifyContent="end">
          <Button variant="outlined" size="medium" fullWidth type="submit">
            Next
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default RFPPOAndCommercialsDetailForm;
