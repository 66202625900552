import { gql } from '@apollo/client';
import { User } from 'types';

export const GET_ME_QUERY = gql`
  query GetMe {
    getMe {
      _id
      empId
      firstName
      lastName
      passwordResetRequired
    }
  }
`;

export type GetMeQueryResponse = {
  getMe: User;
};
