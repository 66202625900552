import EditNoteIcon from '@mui/icons-material/EditNote';
import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { PO } from 'types';

import { SimplePopup } from 'components/Popup';
import UpdatePOForm from 'components/RFPs/Forms/UpdatePOForm';
import Section from 'components/Section';

const RFPPOSection: React.FC<{ po: PO }> = ({ po }) => {
  const [showEditForm, toggleEditForm] = useState(false);

  return (
    <>
      <Section
        title="Purchase Order Details"
        collapsible
        data={[
          {
            label: 'ID',
            value: po.referenceId,
            type: 'STRING',
          },
          {
            label: 'PO or LOI Number',
            value: po.poOrLOIReferenceNumber,
            type: 'STRING',
          },

          {
            label: 'PO or LOI Date',
            value: po.poOrLOIDate,
            type: 'DATE',
          },
        ]}
        action={<EditNoteIcon onClick={() => toggleEditForm(true)} sx={{ cursor: 'pointer' }} />}
      />
      <SimplePopup
        onClose={() => toggleEditForm(false)}
        open={showEditForm}
        title="Update PO"
        fullWidth
      >
        <Grid px={2} pb={3} pt={1} alignItems="center">
          <UpdatePOForm cb={() => toggleEditForm(false)} po={po} />
        </Grid>
      </SimplePopup>
    </>
  );
};

export default RFPPOSection;
