import { gql, useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { RFP } from 'types';

import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import RFPPOSection from 'components/RFPs/Sections/PO';
import RFPSection from 'components/RFPs/Sections/RFP';
import TenderCommercialSection from 'components/RFPs/Sections/TenderCommercials';
import TenderDetailsSection from 'components/RFPs/Sections/TenderDetails';

const GET_RFP_QUERY = gql`
  query GetRfpById($id: ID!) {
    getRfpById(_id: $id) {
      _id
      referenceId
      lead {
        _id
        referenceId
        name
      }
      pq {
        _id
        referenceId
        priority
        status
      }
      tenderDetails {
        _id
        referenceId
        coefficient
        discount
        retention
        startDate
        endDate
        billingType
        submissionMode
        siteVisited
        isGovtTender
        emdNumber
        emdAmount
        emdEndDate
        emdNotifyBeforeDate
        emdDocument {
          _id
          documentMaster {
            _id
            type
            name
            version
            comments
            financialYear
            path
          }
        }
      }
      tenderCommercials {
        _id
        referenceId
        costType
        costSubType
        costValue
        priceValue
        marginValue
      }
      po {
        _id
        referenceId
        poOrLOIReferenceNumber
        poOrLOIDate
      }
      createdBy {
        _id
        firstName
        lastName
      }
    }
  }
`;

type GetRFPQueryResponse = {
  getRfpById: RFP;
};
type GetRFPQueryVariables = {
  id: string;
};

const RFPDetailsPage = () => {
  const { rfpId = '' } = useParams<{ rfpId: string }>();

  const { data: rfp, loading: loadingRFP } = useQuery<GetRFPQueryResponse, GetRFPQueryVariables>(
    GET_RFP_QUERY,
    {
      variables: {
        id: rfpId,
      },
    }
  );

  const loading = loadingRFP || !!!rfp;

  return (
    <Navbar
      goBackButtonConfig={{
        title: 'RFP Details',
      }}
    >
      {loading ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <Grid
          item
          container
          direction="column"
          xs={12}
          md={11}
          lg={9}
          xl={7}
          mt={2}
          mb={5}
          rowGap={2.5}
        >
          <RFPSection rfp={rfp.getRfpById} />
          <TenderDetailsSection rfpTenderDetails={rfp.getRfpById.tenderDetails} />
          <TenderCommercialSection rfpTenderCommercials={rfp.getRfpById.tenderCommercials} />
          <RFPPOSection po={rfp.getRfpById.po} />
        </Grid>
      )}
    </Navbar>
  );
};

export default RFPDetailsPage;
