import { NetworkStatus, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import GridViewIcon from '@mui/icons-material/GridView';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import { updateCachedList } from 'graphql/cacheUpdate';
import {
  GET_DOCUMENT_FILES_QUERY,
  GetFilesQueryResponse,
  GetFilesQueryVariables,
} from 'graphql/query/files';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { FolderTypeEnum } from 'types';

import DocumentFileCard from 'components/DocumentMaster/Card/DocumentCard';
import DocumentFilesListTemplate from 'components/DocumentMaster/DocumentFilesList';
import DocumentFileForm from 'components/DocumentMaster/Forms/DocumentFileForm';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import { SimplePopup } from 'components/Popup';

type DocumentViewType = 'list' | 'grid';

const DocumentsMasterFilesPage = () => {
  const [viewType, setViewType] = useState<DocumentViewType>('grid');
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [showCreateNewForm, toggleCreateNewForm] = useState(false);

  const {
    subFolderId = '',
    folderType = '',
    documentId = '',
  } = useParams<{
    subFolderId: string;
    folderType: FolderTypeEnum;
    documentId: string;
  }>();

  const isLead = folderType === FolderTypeEnum.LEAD;

  const {
    data: docs,
    networkStatus: docsNetworkStatus,
    refetch: reFetchDocs,
  } = useQuery<GetFilesQueryResponse, GetFilesQueryVariables>(GET_DOCUMENT_FILES_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      folder: subFolderId,
      lead: isLead ? documentId : undefined,
      project: isLead ? undefined : documentId,
    },
  });

  const documentActions = [
    <Button variant="contained" size="small" onClick={() => toggleCreateNewForm(true)}>
      <AddIcon fontSize="small" />
    </Button>,
  ];

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
    reFetchDocs(newFilters);
  };

  const loadingDocuments =
    docsNetworkStatus === NetworkStatus.loading ||
    docsNetworkStatus === NetworkStatus.setVariables ||
    !!!docs;

  const handleViewChange = (view: DocumentViewType) => {
    setViewType(view);
  };

  return (
    <Navbar
      goBackButtonConfig={{
        title: 'Documet Master',
      }}
      searchInputConfig={{
        enable: true,
        name: 'documents search',
        placeholder: 'Name',
        handleChange: searchFilter =>
          applyFilters({
            ...filters,
            searchTerm: !!searchFilter ? searchFilter : null,
          }),
      }}
      actionChildren={documentActions}
    >
      <Box mt={2} mb={1}></Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        }}
      >
        <Box>
          <IconButton onClick={() => handleViewChange('grid')} color="primary">
            {viewType === 'grid' ? (
              <GridViewIcon
                fontSize="small"
                sx={{
                  backgroundColor: blue[100],
                  borderRadius: '4px',
                  padding: '4px',
                }}
              />
            ) : (
              <GridViewIcon fontSize="small" />
            )}
          </IconButton>
          <IconButton onClick={() => handleViewChange('list')} color="primary">
            {viewType === 'list' ? (
              <ViewListOutlinedIcon
                fontSize="medium"
                sx={{
                  backgroundColor: blue[100],
                  borderRadius: '4px',
                  padding: '4px',
                }}
              />
            ) : (
              <ViewListOutlinedIcon fontSize="medium" />
            )}
          </IconButton>
        </Box>
      </Box>

      {loadingDocuments ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <Grid minHeight="80vh" mt={2} mb={4}>
          {viewType === 'list' ? (
            <DocumentFilesListTemplate
              docsNetworkStatus={docsNetworkStatus}
              docs={docs}
              fetchDocs={reFetchDocs}
            />
          ) : (
            <Grid container columns={3} rowSpacing={3} columnSpacing={5} alignItems="center">
              {docs.getFiles.map(file => (
                <DocumentFileCard file={file} key={file._id} />
              ))}

              {docs.getFiles.length === 0 && (
                <Grid container style={{ marginLeft: '35rem', marginTop: 50 }}>
                  <Typography variant="subtitle1">No reports to show</Typography>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      )}

      <SimplePopup
        title="Upload New File"
        fullWidth
        onClose={() => toggleCreateNewForm(false)}
        open={showCreateNewForm}
      >
        <Grid py={1}>
          <DocumentFileForm
            folderId={subFolderId}
            cb={res => {
              toggleCreateNewForm(false);
              updateCachedList(GET_DOCUMENT_FILES_QUERY, 'getFiles', res, {
                folder: subFolderId,
                lead: isLead ? documentId : undefined,
                project: isLead ? undefined : documentId,
              });
            }}
          />
        </Grid>
      </SimplePopup>
    </Navbar>
  );
};

export default DocumentsMasterFilesPage;
