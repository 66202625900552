import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, Typography } from '@mui/material';
import { deleteCachedListItem, updateCachedList } from 'graphql/cacheUpdate';
import {
  CREATE_LEAD_PEOPLE_MUTATION,
  CreateLeadPeopleResponse,
  CreateLeadPeopleVariables,
} from 'graphql/mutation/leadPeople';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CompanyType, Lead, LeadPeople, People } from 'types';

import { MultiSelect } from 'components/Inputs/Select';
import LoadingIndicator from 'components/LoadingIndicator';
import ClientEmployeeCard from 'components/Peoples/Card';
import PeopleForm from 'components/Peoples/PeopleForm';
import { ConfirmationPopup, SimplePopup } from 'components/Popup';
import Section from 'components/Section';

import { removeEmptyFields } from 'utils/common';

import { getCompanyAndBranchFieldName } from '../AssociateButton';

export const LEAD_PEOPLE_QUERY = gql`
  query GetLeadPeople($filter: LeadPeopleFilter) {
    getLeadPeople(filter: $filter) {
      _id
      isDecisionMaker
      people {
        _id
        referenceId
        name
        mobile
        designation
        email
        status
      }
      lead {
        name
      }
    }
  }
`;

const GET_UNASSIGNED_LEAD_PEOPLE = gql`
  query FetchUnassignedLeadPeople($lead: ID!, $branch: ID!, $type: CompanyTypeEnum!) {
    fetchUnassignedLeadPeople(lead: $lead, branch: $branch, type: $type) {
      _id
      referenceId
      name
      status
      designation
      email
      mobile
    }
  }
`;

type GetUnassignedLeadPeopleResponse = {
  fetchUnassignedLeadPeople: People[];
};

type GetUnassignedLeadPeopleVariables = {
  lead: string;
  branch: string;
  type: CompanyType;
};

const DELETE_LEAD_PEOPLE_MUTATION = gql`
  mutation DeleteLeadPeople($id: ID!) {
    deleteLeadPeople(_id: $id)
  }
`;

type DeleteLeadPeopleVariables = {
  id: string;
};

type LeadPeopleQueryResponse = {
  getLeadPeople: LeadPeople[];
};

type LeadPeopleQueryVariables = {
  filter: {
    lead?: string;
    people?: string;
    type?: CompanyType;
    searchTerm?: string;
  };
};

const TOGGLE_DECISION_MAKER_MUTATION = gql`
  mutation ToggleDecisionMaker($id: ID!, $isDecisionMaker: Boolean!) {
    toggleDecisionMaker(_id: $id, isDecisionMaker: $isDecisionMaker) {
      _id
      referenceId
      isDecisionMaker
    }
  }
`;

type ToggleDecisionMakerMutationResponse = {
  toggleDecisionMaker: LeadPeople;
};

type ToggleDecisionMakerMutationVariables = {
  id: string;
  isDecisionMaker?: boolean;
};

const LeadPeopleSection: React.FC<{ lead: Lead }> = ({ lead }) => {
  const [enableAddNewForm, toggleAddNewForm] = useState(false);
  const [showDeleteConfirmationPopup, toggleDeleteConfirmationPopup] = useState({
    state: false,
    id: '',
  });

  const { companyType } = useParams<{ companyType: CompanyType }>();

  const { data: leadPeople, loading: loadingLeadPeople } = useQuery<
    LeadPeopleQueryResponse,
    LeadPeopleQueryVariables
  >(LEAD_PEOPLE_QUERY, {
    variables: {
      filter: {
        lead: lead._id,
        type: companyType,
      },
    },
  });

  const [toggleDecisionMaker] = useMutation<
    ToggleDecisionMakerMutationResponse,
    ToggleDecisionMakerMutationVariables
  >(TOGGLE_DECISION_MAKER_MUTATION);

  const [deleteLeadPeople, { loading: deletingLeadPeople }] = useMutation<
    {},
    DeleteLeadPeopleVariables
  >(DELETE_LEAD_PEOPLE_MUTATION);

  return (
    <>
      <Section
        title="Lead People"
        collapsible
        action={
          <PersonAddIcon
            fontSize="small"
            sx={{ cursor: 'pointer' }}
            onClick={() => toggleAddNewForm(true)}
          />
        }
      >
        {loadingLeadPeople ? (
          <Grid>
            <LoadingIndicator size="1.6rem" />
          </Grid>
        ) : (
          <Grid
            container
            columns={2}
            rowSpacing={2}
            columnSpacing={3}
            alignItems="center"
            minHeight="10vh"
            pb={2}
            pr={1}
            overflow="auto"
            mt={-1}
          >
            {leadPeople?.getLeadPeople.map(lp => (
              <Grid item key={lp.people.referenceId} xs={2} md={1} lg={1}>
                <ClientEmployeeCard
                  people={lp.people}
                  isDecisionMaker={lp.isDecisionMaker}
                  action={[
                    {
                      title: !!lp.isDecisionMaker ? 'Remove from Decision Maker' : 'Decision Maker',
                      onClick: () =>
                        toggleDecisionMaker({
                          variables: {
                            id: lp._id,
                            isDecisionMaker: !!!lp.isDecisionMaker,
                          },
                          onCompleted: res =>
                            res.toggleDecisionMaker.isDecisionMaker
                              ? toast.success('Succesfully appointed as a Decision Maker.')
                              : toast.success('Succesfully removed as a Decision Maker.'),
                        }),
                      disabled: false,
                    },
                    {
                      title: 'Delete',
                      disabled: false,
                      onClick: () => toggleDeleteConfirmationPopup({ id: lp._id, state: true }),
                    },
                  ]}
                />
              </Grid>
            ))}
            {leadPeople?.getLeadPeople.length === 0 && (
              <Grid item my="auto" mt={2} container justifyContent="center">
                <Typography variant="subtitle2">No contacts has been added yet.</Typography>
              </Grid>
            )}
          </Grid>
        )}
      </Section>
      <SimplePopup
        onClose={() => toggleAddNewForm(false)}
        open={enableAddNewForm}
        title="Add Lead People"
        fullWidth
      >
        <Box pb={2.5}>
          <AddLeadPeopleForm
            lead={lead}
            companyType={companyType as CompanyType}
            cb={lps => {
              updateCachedList(LEAD_PEOPLE_QUERY, 'getLeadPeople', lps, {
                filter: {
                  lead: lead._id,
                  type: companyType,
                },
              });
              toggleAddNewForm(false);
            }}
          />
        </Box>
      </SimplePopup>
      <ConfirmationPopup
        onClose={() => toggleDeleteConfirmationPopup({ id: '', state: false })}
        onConfirmation={() =>
          deleteLeadPeople({
            variables: {
              id: showDeleteConfirmationPopup.id,
            },
            onCompleted: _ => {
              deleteCachedListItem(
                LEAD_PEOPLE_QUERY,
                'getLeadPeople',
                showDeleteConfirmationPopup.id,
                {
                  filter: {
                    lead: lead._id,
                    type: companyType,
                  },
                }
              );
              toggleDeleteConfirmationPopup({ id: '', state: false });
              toast.success('Contact Deleted Successfully');
            },
          })
        }
        open={showDeleteConfirmationPopup.state}
        title="Do you want to delete this contact?"
        confirmationLabel="Yes"
        loading={deletingLeadPeople}
      />
    </>
  );
};

const AddLeadPeopleForm: React.FC<{
  lead: Lead;
  companyType: CompanyType;
  cb: (lps: LeadPeople[]) => void;
}> = ({ lead, companyType, cb }) => {
  const [formState, setFormState] = useState<Record<string, any>>({});
  const [enableAddNewPeopleForm, toggleAddNewPeople] = useState(false);

  const [createLeadPeople, { loading: creatingLeadPeople }] = useMutation<
    CreateLeadPeopleResponse,
    CreateLeadPeopleVariables
  >(CREATE_LEAD_PEOPLE_MUTATION);

  const [
    getUnassignedLeadPeople,
    { loading: loadingUnassignedLeadPeople, data: unassignedLeadpeople },
  ] = useLazyQuery<GetUnassignedLeadPeopleResponse, GetUnassignedLeadPeopleVariables>(
    GET_UNASSIGNED_LEAD_PEOPLE
  );

  const { branchFieldName, companyFieldName } = getCompanyAndBranchFieldName(companyType);

  const transformPeopleToOptions = (people: People[]) => {
    return people.map(p => ({ label: `${p.name} (${p.mobile})`, value: p._id }));
  };

  const handleChange = (fieldName: string, val: any) => {
    setFormState(pre => ({
      ...pre,
      [fieldName]: val,
    }));
  };

  const handleSubmit = () => {
    const refinedFields = removeEmptyFields(formState);

    createLeadPeople({
      variables: {
        input: {
          lead: lead._id,
          people: refinedFields.people,
          type: companyType,
        },
      },
      onCompleted: lp => cb(lp.createLeadPeople),
    });
  };

  return (
    <>
      <form
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Grid container columnSpacing={2} rowGap={2} px={2} py={1.5}>
          <Grid item xs={12}>
            <MultiSelect
              label="People"
              value={formState.people ?? []}
              required
              handleChange={val => {
                handleChange('people', val);
              }}
              options={
                !!unassignedLeadpeople?.fetchUnassignedLeadPeople
                  ? transformPeopleToOptions(unassignedLeadpeople.fetchUnassignedLeadPeople)
                  : []
              }
              fetchConfig={{
                fetchFn: getUnassignedLeadPeople,
                loading: loadingUnassignedLeadPeople,
                variables: {
                  lead: lead._id,
                  type: companyType,
                  branch: lead[branchFieldName]._id,
                },
              }}
              addNewConfig={{
                enableAddNew: true,
                onClick: () => toggleAddNewPeople(true),
                addNewLabel: '+ Add New Person',
              }}
            />
          </Grid>
          <Grid item xs={12} container justifyContent="end">
            <LoadingButton variant="contained" type="submit" loading={creatingLeadPeople}>
              Add
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
      <SimplePopup
        onClose={() => toggleAddNewPeople(false)}
        open={enableAddNewPeopleForm}
        title="Add People"
        fullWidth
      >
        <PeopleForm
          cb={people => {
            toggleAddNewPeople(false);
            updateCachedList(GET_UNASSIGNED_LEAD_PEOPLE, 'fetchUnassignedLeadPeople', people, {
              lead: lead._id,
              type: companyType,
              branch: lead[branchFieldName]._id,
            });
            setFormState(prev => ({
              ...prev,
              people: [...(prev.people ?? []), people._id],
            }));
          }}
          branchId={lead[branchFieldName]._id}
          companyId={lead[companyFieldName]._id}
        />
      </SimplePopup>
    </>
  );
};

export default LeadPeopleSection;
