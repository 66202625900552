import { Grid, SxProps, Theme, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BillingTypeEnum, CostSubTypeEnum, CostTypeEnum, RFP, SubmissionModeEnum } from 'types';

import Card from 'components/Card';

import { convertAmountToIndianUnits } from 'utils/formatHelper';
import { getEnumKeyFromValue } from 'utils/transformFn';

const RFPTemplate: React.FC<{ rfps: RFP[] }> = ({ rfps }) => {
  const navigate = useNavigate();

  const rfpCustomCSS = () =>
    ({
      cursor: 'pointer',
    } as SxProps<Theme>);

  return (
    <Grid container columns={3} rowSpacing={3} columnSpacing={5} alignItems="center" mb={5}>
      {rfps.map(rfp => (
        <Grid item key={rfp.referenceId} width={'100%'} md={1.5} lg={1}>
          <Card
            headers={[
              rfp.po.poOrLOIReferenceNumber,
              getEnumKeyFromValue(BillingTypeEnum, rfp.tenderDetails.billingType) as string,
              getEnumKeyFromValue(SubmissionModeEnum, rfp.tenderDetails.submissionMode) as string,
            ]}
            onClick={() => navigate(rfp._id)}
            data={[
              {
                label: 'Cost Value',
                value: convertAmountToIndianUnits(rfp.tenderCommercials.costValue),
              },
              {
                label: 'Price Value',
                value: convertAmountToIndianUnits(rfp.tenderCommercials.priceValue),
              },
              {
                label: 'Margin',
                value: convertAmountToIndianUnits(rfp.tenderCommercials.marginValue),
              },
            ]}
            sx={rfpCustomCSS()}
          >
            <Grid container direction="column" rowGap={1} mt={1.5}>
              <Grid item container justifyContent={'space-between'}>
                <Typography variant="body1" fontWeight={500}>
                  {getEnumKeyFromValue(CostTypeEnum, rfp.tenderCommercials.costType)}
                </Typography>
                <Typography variant="body1" fontWeight={500}>
                  {getEnumKeyFromValue(CostSubTypeEnum, rfp.tenderCommercials.costSubType)}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
export default RFPTemplate;
