import { NetworkStatus, gql, useQuery } from '@apollo/client';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Grid } from '@mui/material';
import { updateCachedList } from 'graphql/cacheUpdate';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Branch, BranchStatusEnum, Company, CompanyStatus } from 'types';

import LoadingIndicator from 'components/LoadingIndicator';
import { SimplePopup } from 'components/Popup';
import Section from 'components/Section';

import BranchCard from './Card';
import BranchCreateForm from './Form/BranchCreateForm';

const GET_BRANCHES_QUERY = gql`
  query GetBranches($filter: BranchFilter) {
    getBranches(filter: $filter) {
      _id
      email
      landline1
      landline2
      landline3
      mobile1
      mobile2
      name
      referenceId
      status
      type
      address {
        _id
        addressLine1
        addressLine2
        addressLocation
        locationUrl
        city
        country
        pincode
        state
      }
    }
  }
`;

type BranchesQueryResponse = {
  getBranches: Branch[];
};
type BranchesQueryVariables = {
  filter: {
    city?: string;
    state?: string;
    country?: string;
    company?: string;
    status?: BranchStatusEnum;
    searchTerm?: string;
  };
};

const Branches: React.FC<{ company: Company }> = ({ company }) => {
  const [enableAddNewForm, toggleAddNewForm] = useState(false);
  const isCompanyInActive = company.status === CompanyStatus.INACTIVE;
  const navigate = useNavigate();

  const { data: branches, networkStatus } = useQuery<BranchesQueryResponse, BranchesQueryVariables>(
    GET_BRANCHES_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        filter: {
          company: company._id,
        },
      },
    }
  );

  const loadingBranches =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!branches;

  return (
    <Grid container direction="column">
      <Section
        title="Branches"
        action={
          <PersonAddIcon
            fontSize="small"
            sx={
              isCompanyInActive
                ? {
                    pointerEvents: 'none',
                    color: 'gray',
                  }
                : { cursor: 'pointer' }
            }
            onClick={() => toggleAddNewForm(true)}
          />
        }
      >
        {loadingBranches ? (
          <Grid>
            <LoadingIndicator size="1.6rem" />
          </Grid>
        ) : (
          <Grid container columns={2} rowSpacing={2} columnSpacing={3} mb={5} alignItems="center">
            {branches.getBranches.map(b => (
              <Grid item key={b.referenceId} xs={2} md={1} lg={1}>
                <BranchCard
                  headers={[b.name, b.referenceId]}
                  onClick={() => navigate(`branches/${b._id}`)}
                  branch={b}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Section>
      <SimplePopup
        onClose={() => toggleAddNewForm(false)}
        open={enableAddNewForm}
        title="Create Branch"
        fullWidth
      >
        <BranchCreateForm
          toggleForm={toggleAddNewForm}
          cb={newBranch => {
            updateCachedList(GET_BRANCHES_QUERY, 'getBranches', newBranch, {
              filter: {
                company: company._id,
              },
            });
          }}
        />
      </SimplePopup>
    </Grid>
  );
};

export default Branches;
