import { gql } from '@apollo/client';
import { Lead } from 'types';

export const UPDATE_LEAD_ASSOCIATE_MUTATION = gql`
  mutation UpdateLeadAssociates($input: UpdateLeadAssociatesInput!) {
    updateLeadAssociates(input: $input) {
      _id
      referenceId
      name
      company {
        _id
        referenceId
        name
      }
      branch {
        _id
        referenceId
        name
      }
      architect {
        _id
        referenceId
        name
      }
      architectBranch {
        _id
        referenceId
        name
      }
      pmc {
        _id
        referenceId
        name
      }
      pmcBranch {
        _id
        referenceId
        name
      }
      pmo {
        _id
        referenceId
        name
      }
      pmoBranch {
        _id
        referenceId
        name
      }
      costConsultant {
        _id
        referenceId
        name
      }
      costConsultantBranch {
        _id
        referenceId
        name
      }
      wkConsultant {
        _id
        referenceId
        name
      }
      wkConsultantBranch {
        _id
        referenceId
        name
      }
      others {
        _id
        referenceId
        name
      }
      competitors {
        _id
        referenceId
        name
      }
      scope
      areaOrPower
      areaOrPowerUOM
      approxValue
      probability
      expectedPOMonth
      actualPOMonth
      leadStatus
    }
  }
`;

export type LeadAssociateMuationResponse = {
  updateLeadAssociates: Lead;
};

export type LeadAssociateMuationVariables = {
  input: {
    _id: string;
    company?: string;
    branch?: string;
    architect?: string;
    architectBranch?: string;
    pmc?: string;
    pmcBranch?: string;
    costConsultant?: string;
    costConsultantBranch?: string;
    wkConsultant?: string;
    wkConsultantBranch?: string;
  };
};
