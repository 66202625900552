import { useMutation } from '@apollo/client';
import EditIcon from '@mui/icons-material/Edit';
import { Card, Grid, Typography } from '@mui/material';
import {
  UPDATE_FOLDER_MUTATION,
  UpdateFolderMutationResponse,
  UpdateFolderMutationVariables,
} from 'graphql/mutation/folder';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import extendedTheme from 'styles/theme';
import { Folder, IconProps } from 'types';

import { FormInput, FormPanel } from 'components/FormPanel';
import { SimplePopup } from 'components/Popup';

import AwarsdsRecognitionsicon from 'media/icons/awards-recognitions';
import Billingicon from 'media/icons/billing';
import Complianceicon from 'media/icons/compliance';
import DailyProjectupdateicon from 'media/icons/daily-project-update';
import DesignArtifactsicon from 'media/icons/design-artifacts';
import DesignProjectstatusicon from 'media/icons/design-project-status';
import DlpServiceicon from 'media/icons/dlp-service';
import EmployeeAttendanceicon from 'media/icons/employee-attendance';
import FactoryDeliverystatusicon from 'media/icons/factory-delivery-status';
import FoldersIcon from 'media/icons/folder-icon';
import ImageGalleryicon from 'media/icons/image-gallery';
import Labouricon from 'media/icons/labour';
import MaterialStatusicon from 'media/icons/material-status';
import OverallProjectupdateicon from 'media/icons/overall-project-update';
import PerformanceKpisicon from 'media/icons/performance-kpi';
import ProjectClosureicon from 'media/icons/project-closure';
import Qualityicon from 'media/icons/quality';
import SiteEhsicon from 'media/icons/site-ehs';
import VendorPaymentstatusicon from 'media/icons/vendor-payment-status';

const FolderCard = ({ folder }: { folder: Folder }) => {
  const [enableEditForm, toggleEditForm] = useState(false);
  const [FolderIcon, setFolderIcon] = useState<React.FC<IconProps> | null>(null);
  const [isHovered, setIsHovered] = useState(false);

  const [updateFolder, { loading: updatingFolder }] = useMutation<
    UpdateFolderMutationResponse,
    UpdateFolderMutationVariables
  >(UPDATE_FOLDER_MUTATION);

  const navigate = useNavigate();

  useEffect(() => {
    switch (folder.name) {
      case 'Design Artifacts':
        setFolderIcon(() => DesignArtifactsicon);
        break;
      case 'Design & Project Status':
        setFolderIcon(() => DesignProjectstatusicon);
        break;
      case 'Overall Project Update':
        setFolderIcon(() => OverallProjectupdateicon);
        break;
      case 'Site EHS':
        setFolderIcon(() => SiteEhsicon);
        break;
      case "Performance KPI's":
        setFolderIcon(() => PerformanceKpisicon);
        break;
      case 'Compliance':
        setFolderIcon(() => Complianceicon);
        break;
      case 'Awards & Recognitions':
        setFolderIcon(() => AwarsdsRecognitionsicon);
        break;
      case 'Billing':
        setFolderIcon(() => Billingicon);
        break;
      case 'Daily Project Update':
        setFolderIcon(() => DailyProjectupdateicon);
        break;
      case 'Project Closure':
        setFolderIcon(() => ProjectClosureicon);
        break;
      case 'Image Gallery':
        setFolderIcon(() => ImageGalleryicon);
        break;
      case 'Material Status':
        setFolderIcon(() => MaterialStatusicon);
        break;
      case 'DLP Service':
        setFolderIcon(() => DlpServiceicon);
        break;
      case 'CL Employees Attendance':
        setFolderIcon(() => EmployeeAttendanceicon);
        break;
      case 'Labour':
        setFolderIcon(() => Labouricon);
        break;
      case 'Quality':
        setFolderIcon(() => Qualityicon);
        break;
      case 'Factory Delivery Status':
        setFolderIcon(() => FactoryDeliverystatusicon);
        break;
      case 'Vendor Payment Status':
        setFolderIcon(() => VendorPaymentstatusicon);
        break;
      default:
        setFolderIcon(() => FoldersIcon);
        break;
    }
  }, []);

  const handleFolderRename = (data: { name: string }) => {
    updateFolder({
      variables: {
        input: {
          _id: folder._id,
          name: data.name,
        },
      },
      onCompleted: _ => toggleEditForm(false),
    });
  };

  return (
    <Card
      elevation={isHovered ? 6 : 3}
      sx={{
        overflow: 'visible',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <Grid
        container
        direction="column"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Grid
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            transform: 'translate(25%, -25%)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '24px',
            height: '24px',
            cursor: 'pointer',
            transition: 'opacity 0.3s ease-in-out',
            borderRadius: '50%',
            bgcolor: 'lightgray',
            opacity: !folder.isStandardFolder && isHovered ? 0 : 1,
          }}
        >
          <Typography variant="caption" fontWeight={600}>
            {folder.fileCount ?? 0}
          </Typography>
        </Grid>
        {!folder.isStandardFolder && (
          <Grid
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              transform: 'translate(30%, -30%)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '24px',
              height: '24px',
              cursor: 'pointer',
              transition: 'opacity 0.3s ease-in-out',
              opacity: isHovered ? 1 : 0,
            }}
            onClick={() => toggleEditForm(true)}
          >
            <EditIcon fontSize="small" color="primary" />
          </Grid>
        )}
        <Grid
          item
          minHeight="7rem"
          container
          alignItems="center"
          justifyContent="center"
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate(`${folder._id}`)}
        >
          {!!FolderIcon ? <FolderIcon color={extendedTheme.palette.secondary.light} /> : null}
        </Grid>
        <Grid
          item
          xs={12}
          container
          direction="column"
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate(`${folder._id}`)}
        >
          <Typography
            variant="subtitle2"
            bgcolor={theme => theme.palette.primary.light}
            color="white"
            fontWeight={600}
            py={0.7}
            px={1}
            textAlign="center"
          >
            {folder.name}
          </Typography>
        </Grid>
      </Grid>
      <SimplePopup
        onClose={() => toggleEditForm(false)}
        open={enableEditForm}
        title="Rename Folder"
        fullWidth
      >
        <Grid container direction="column" px={2} py={1.5}>
          <FormPanel
            error={null}
            loading={updatingFolder}
            onSubmit={handleFolderRename}
            submitButtonLabel="Rename"
          >
            <FormInput
              type="string"
              defaultValue={folder.name}
              label="Folder Name"
              fieldName="name"
              fullWidth
              validators={{
                required: true,
              }}
            />
          </FormPanel>
        </Grid>
      </SimplePopup>
    </Card>
  );
};

export default FolderCard;
