import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dispatch, FC, useContext } from 'react';
import { FilterProps } from 'types/common';

import Fieldset from 'components/Fieldset';
import AutocompleteWithFetch from 'components/FormPanel/AutoCompleteWithFetch';

import { getDate } from 'utils/formatHelper';
import getStatusDetail from 'utils/statusMessage';

import FormPanelContext from '../../FormPanel/formPanelContext';
import { ActionType, UpdateStateActionType } from '../../FormPanel/formPanelReducer';
import Selection from '../../Inputs/Selection';
import theme from './theme.module.scss';

export const Filter: FC<FilterProps> = ({
  fieldName,
  type,
  label,
  editable = true,
  readOnlyMode = false,
  options,
  autoCompleteConfig,
  fullWidth = false,
  validators,
  minDate,
  maxDate,
  placeholder,
  conditionToShow,
  dangerouslySetInnerHTML,
  minLabel = 'From Date',
  maxLabel = 'To Date',
}) => {
  const { formState, formDispatch } = useContext(FormPanelContext);
  const updateStateDispatch: Dispatch<ActionType & UpdateStateActionType> = formDispatch;

  const hideFormInput =
    conditionToShow &&
    ![...conditionToShow].every(c => {
      switch (c.condition) {
        case 'in':
          return c.values.includes(formState.data[c.field]);
        case 'not_in':
          return !c.values.includes(formState.data[c.field]);
        default:
          // eslint-disable-next-line
          return eval(`"${formState.data[c.field]}" ${c.condition} "${c.value}"`);
      }
    });

  if (validators) {
    if (validators.required !== undefined) {
      validators.required = !hideFormInput;
    }
    if (validators.isPhoneNumber !== undefined) {
      validators.isPhoneNumber = !hideFormInput;
    }
  }

  const handleChange = value =>
    updateStateDispatch({
      type: 'UPDATE_STATE',
      payload: {
        fieldName,
        value,
      },
    });

  if (hideFormInput) {
    return null;
  }

  const inputField = () => {
    switch (type) {
      case 'select':
        const selectedValue = (formState.data[fieldName] as string) ?? [''];
        return (
          <FormControl fullWidth>
            <InputLabel id={fieldName + '-label'}>{label}</InputLabel>
            <Select
              labelId={fieldName + '-label'}
              id={fieldName}
              value={selectedValue[0]}
              defaultValue=""
              name={fieldName}
              onChange={e => handleChange([e.target.value])}
              error={!!(formState.errors && formState.errors[fieldName])}
              label={label}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {options &&
                options.map(o => (
                  <MenuItem key={o.value} value={o.value}>
                    {o.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        );
      case 'multiple_select':
        return (
          <>
            <FormControl fullWidth>
              <InputLabel id={fieldName + '-label'}>{label}</InputLabel>
              <Select
                multiple
                labelId={fieldName + '-label'}
                label={label}
                name={fieldName}
                id={fieldName}
                value={formState.data[fieldName]}
                onChange={e =>
                  handleChange(
                    // On autofill we get a stringified value.
                    typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value
                  )
                }
                renderValue={selected => {
                  return (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map(value => (
                        <Chip
                          key={value}
                          size="small"
                          label={options?.find(o => o.value === value)?.label}
                        />
                      ))}
                    </Box>
                  );
                }}
                MenuProps={{ PaperProps: { sx: { maxHeight: '75%' } } }}
              >
                {options &&
                  options.map(o => (
                    <MenuItem key={o.value} value={o.value}>
                      <Checkbox
                        size="small"
                        checked={formState.data[fieldName].indexOf(o.value) > -1}
                      />
                      {o.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <p className={theme.helperTextError}>
              {formState.errors && formState.errors[fieldName]}
            </p>
          </>
        );
      case 'select_with_search':
        return (
          <>
            <FormControl fullWidth>
              <Autocomplete
                id={fieldName}
                value={formState.data[fieldName][0] ?? { name: '', id: '' }}
                getOptionLabel={option => option.name}
                isOptionEqualToValue={(option, value) => option._id === value._id}
                fullWidth
                includeInputInList
                onChange={(e, val) => handleChange(val ? [val] : [])}
                options={autoCompleteConfig ? autoCompleteConfig.options : []}
                renderInput={params => {
                  return <TextField {...params} label={label} />;
                }}
              />
            </FormControl>
            <p className={theme.helperTextError}>
              {formState.errors && formState.errors[fieldName]}
            </p>
          </>
        );
      case 'auto_complete_with_fetch':
        const config = autoCompleteConfig ?? {
          loading: true,
          options: [{ name: '', _id: '', referenceId: '' }],
          fetchOptionsFn: () => {},
          labelWithId: false,
        };

        return (
          <AutocompleteWithFetch
            value={formState.data[fieldName][0] ?? { name: '', _id: '', referenceId: '' }}
            loading={config.loading}
            options={config.options}
            handleChange={val => handleChange([val])}
            label={label}
            labelWithId={!!config.labelWithId}
            fetch={config.fetchOptionsFn}
          />
        );
      case 'selection':
        return (
          <>
            <Fieldset label={label}>
              <Grid container spacing={2}>
                {options &&
                  options.map(option => (
                    <Grid item xs={6} key={option.label + JSON.stringify(option.value)}>
                      <Selection
                        activeId={formState.data[fieldName][0]}
                        handleChange={handleChange}
                        id={option.value}
                        label={option.label}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Fieldset>
            <p className={theme.helperTextError}>
              {formState.errors && formState.errors[fieldName]}
            </p>
          </>
        );
      case 'date':
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FormControl fullWidth>
              <MobileDatePicker
                label={label}
                value={formState.data[fieldName]}
                format="DD/MM/YYYY"
                onChange={newValue => {
                  handleChange(newValue);
                }}
                minDate={minDate}
                maxDate={maxDate}
              />
            </FormControl>
            <p className={theme.helperTextError}>
              {formState.errors && formState.errors[fieldName]}
            </p>
          </LocalizationProvider>
        );
      case 'date-range':
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Fieldset label={label}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <MobileDatePicker
                      label={minLabel}
                      value={formState.data[fieldName][0]}
                      format="DD/MM/YYYY"
                      maxDate={formState.data[fieldName][1]}
                      onChange={newValue => handleChange([newValue, formState.data[fieldName][1]])}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <MobileDatePicker
                      label={maxLabel}
                      value={formState.data[fieldName][1]}
                      minDate={formState.data[fieldName][0]}
                      disabled={!formState.data[fieldName][0]}
                      format="DD/MM/YYYY"
                      onChange={newValue => handleChange([formState.data[fieldName][0], newValue])}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <p className={theme.helperTextError}>
                {formState.errors && formState.errors[fieldName]}
              </p>
            </Fieldset>
          </LocalizationProvider>
        );
      case 'range-slider':
        const rangeValue = (formState.data[fieldName] as number[]) ?? ['', ''];
        return (
          <>
            <Fieldset label={label}>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={6}>
                  <TextField
                    label={minLabel}
                    value={rangeValue[0]}
                    fullWidth
                    error={!!(formState.errors && formState.errors[fieldName])}
                    type="number"
                    onChange={e =>
                      handleChange([e.target.value ? +e.target.value : '', rangeValue[1]])
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={maxLabel}
                    fullWidth
                    value={rangeValue[1]}
                    error={!!(formState.errors && formState.errors[fieldName])}
                    onChange={e =>
                      handleChange([rangeValue[0], e.target.value ? +e.target.value : ''])
                    }
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Fieldset>
            <p className={theme.helperTextError}>
              {formState.errors && formState.errors[fieldName]}
            </p>
          </>
        );
      default:
        return (
          <TextField
            size="small"
            fullWidth={fullWidth}
            value={formState.data[fieldName]}
            error={!!(formState.errors && formState.errors[fieldName])}
            onChange={e => handleChange(e.target.value)}
            helperText={formState.errors ? formState.errors[fieldName] : ''}
          />
        );
    }
  };

  const staticText = () => {
    if (type === 'select') {
      return (
        <span className={theme.status}>
          {getStatusDetail(formState.data[fieldName]).labelWithBadge}
        </span>
      );
    }

    if (type === 'multiple_select') {
      return formState.data[fieldName].map(value => (
        <span key={value} className={theme.status}>
          {getStatusDetail(value).labelWithBadge}
        </span>
      ));
    }

    if (type === 'date-range') {
      const startDate = getDate(formState.data.date[0], 'MM/DD/YYYY');
      const endDate = getDate(formState.data.date[1], 'MM/DD/YYYY');

      return <div className={theme.value}>{`${startDate} ${endDate}`}</div>;
    }

    if (dangerouslySetInnerHTML) {
      return <div dangerouslySetInnerHTML={{ __html: formState.data[fieldName] }} />;
    }

    return (
      <p style={{ overflowWrap: 'anywhere', marginTop: 0 }}>
        {formState.data[fieldName] !== (undefined || null) ? formState.data[fieldName] : '-'}
      </p>
    );
  };

  if (validators?.dependsOn && !formState.data[validators.dependsOn[0]]) {
    return null;
  }

  return (
    <>
      <Grid item gridColumn={fullWidth ? '1/-1' : 'span 1/span 1'}>
        {readOnlyMode || !editable ? staticText() : inputField()}
      </Grid>
    </>
  );
};
