import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { FormControl, Grid } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { GetLeadsQueryResponse } from 'graphql/query/lead';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CompanyType,
  Followup,
  FollowupTypeEnum,
  Lead,
  LeadLostReasonEnum,
  LeadPeople,
  LeadStatusEnum,
  LeadStatusWithProbabilityEnum,
  POIssuedByEnum,
} from 'types';

import AutocompleteWithFetch from 'components/FormPanel/AutoCompleteWithFetch';
import { Select } from 'components/Inputs/Select';
import TextField from 'components/Inputs/TextField';
import Navbar from 'components/Navbar';
import Note from 'components/Note';

import { isObject, removeEmptyFields } from 'utils/common';
import { stringToDateFormatter } from 'utils/formatHelper';
import { formatDate } from 'utils/transformFn';

const GET_LEADS_QUERY = gql`
  query GetLeads($filter: LeadFilter) {
    getLeads(filter: $filter) {
      _id
      referenceId
      name
      leadStatus
    }
  }
`;

const GET_LEAD_PEOPLE_TYPES = gql`
  query Query($lead: ID!) {
    getLeadPeopleTypes(lead: $lead)
  }
`;

type GetLeadPeopleTypesResponse = {
  getLeadPeopleTypes: string[];
};

type GetLeadPeopleTypesVariables = {
  lead: string;
};

const GET_LEAD_PEOPLE_QUERY = gql`
  query GetLeadPeople($filter: LeadPeopleFilter) {
    getLeadPeople(filter: $filter) {
      _id
      people {
        _id
        name
        referenceId
      }
    }
  }
`;

type GetLeadPeopleQueryResponse = {
  getLeadPeople: LeadPeople[];
};

type GetLeadPeopleQueryVariables = {
  filter?: {
    lead?: string;
    people?: string;
    type?: CompanyType;
    searchTerm?: string;
  };
};

const CREATE_FOLLOWUP_MUTATION = gql`
  mutation CreateFollowup($input: CreateFollowupInput!) {
    createFollowup(input: $input) {
      _id
      referenceId
      lead {
        _id
        name
        referenceId
      }
      leadStatus
      followupType
      followupDate
      comments
      lostReason
      followupContact {
        people {
          _id
          name
        }
      }
    }
  }
`;

type CreateFollowupMutationResponse = {
  createFollowup: Followup;
};

type CreateFollowupMutationVariables = {
  input: {
    lead: string;
    type: FollowupTypeEnum;
    followupDate: string;
    leadStatus: LeadStatusEnum;
    comments: string;
    lostReason?: LeadLostReasonEnum;
  };
};

const GET_LEAD_BY_ID_QUERY = gql`
  query GetLeadById($id: ID!) {
    getLeadById(_id: $id) {
      _id
      name
      referenceId
      lostReason
      leadStatus
      rfpReceivedDate
      rfpSubmittedDate
      pqReceivedDate
      pqSubmittedDate
      poAmount
      actualPODate
      poNumber
      poIssuedBy
      probability
    }
  }
`;

type GetLeadByIdQueryResponse = {
  getLeadById: Lead;
};
type GetLeadByIdQueryVariables = {
  id: string;
};

const getLeadStatusFromProbability = (probability: number): LeadStatusEnum => {
  switch (probability) {
    case 0:
      return LeadStatusEnum.CREATED;
    case 25:
      return LeadStatusEnum.WIP;
    case 50:
      return LeadStatusEnum['PQ SUBMITTED'];
    case 75:
      return LeadStatusEnum['RFP SUBMITTED'];
    case 100:
      return LeadStatusEnum.WON;
    default:
      return LeadStatusEnum.CREATED;
  }
};

const CreateFollowup = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const parsedData = JSON.parse(queryParams.get('data') ?? '{}');

  let fromData: Record<string, any> = {};
  if (isObject(parsedData) && Object.keys(parsedData).length > 0) fromData = parsedData;

  const [formState, setFormState] = useState<Record<string, any>>(fromData ?? {});

  const [getLeadPeopleTypes, { data: leadPeopleTypes, loading: loadingLeadPeopleTypes }] =
    useLazyQuery<GetLeadPeopleTypesResponse, GetLeadPeopleTypesVariables>(GET_LEAD_PEOPLE_TYPES, {
      variables: {
        lead: formState?.lead?._id,
      },
    });

  const [getLeads, { loading: loadingLeads, data: leads }] =
    useLazyQuery<GetLeadsQueryResponse>(GET_LEADS_QUERY);

  const [createFollowup, { loading: creatingFollowup }] = useMutation<
    CreateFollowupMutationResponse,
    CreateFollowupMutationVariables
  >(CREATE_FOLLOWUP_MUTATION);

  const [getLeadPeople, { data: leadPeople, loading: loadingLeadPeople }] = useLazyQuery<
    GetLeadPeopleQueryResponse,
    GetLeadPeopleQueryVariables
  >(GET_LEAD_PEOPLE_QUERY);

  const [getLeadById, { loading: loadingSelectedLead, data: selectedLead }] = useLazyQuery<
    GetLeadByIdQueryResponse,
    GetLeadByIdQueryVariables
  >(GET_LEAD_BY_ID_QUERY, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!!fromData?.lead?._id) {
      getLeadById({
        variables: {
          id: fromData.lead._id,
        },
      });
    }
  }, []);

  useEffect(() => {
    const assignLeadStatus = (lead: Lead) => {
      const status = lead.leadStatus;
      const data: Partial<Lead> = {};
      switch (status) {
        case LeadStatusEnum['PQ RECEIVED']:
          data.pqReceivedDate = lead.pqReceivedDate;
          break;
        case LeadStatusEnum['PQ SUBMITTED']:
          data.pqSubmittedDate = lead.pqSubmittedDate;
          break;
        case LeadStatusEnum['RFP RECEIVED']:
          data.rfpReceivedDate = lead.rfpReceivedDate;
          break;
        case LeadStatusEnum['RFP SUBMITTED']:
          data.rfpSubmittedDate = lead.rfpSubmittedDate;
          break;
        case LeadStatusEnum.WON:
          data.poAmount = lead.poAmount;
          data.poIssuedBy = lead.poIssuedBy;
          data.poNumber = lead.poNumber;
          data.actualPODate = lead.actualPODate;
          break;
        case LeadStatusEnum.LOST:
          data.lostReason = lead.lostReason;
          break;
        default:
          break;
      }
      return data;
    };

    if (!loadingSelectedLead && !!selectedLead) {
      const fieldsToUpdate =
        selectedLead.getLeadById.leadStatus === LeadStatusEnum['ON HOLD']
          ? assignLeadStatus({
              ...selectedLead.getLeadById,
              leadStatus: getLeadStatusFromProbability(selectedLead.getLeadById.probability),
            })
          : assignLeadStatus(selectedLead.getLeadById);
      setFormState(prev => ({
        ...prev,
        ...fieldsToUpdate,
        leadStatus: selectedLead.getLeadById.leadStatus,
      }));
    }
  }, [loadingSelectedLead]);

  const getLeadStatusOptions = () => {
    if (!!!selectedLead?.getLeadById?.leadStatus) {
      return [];
    }

    const statusKeys = Object.keys(LeadStatusWithProbabilityEnum);
    const indexMap = {
      CREATED: 0,
      WORK_IN_PROGRESS: 1,
      PQ_RECEIVED: 2,
      PQ_SUBMITTED: 3,
      RFP_RECEIVED: 4,
      RFP_SUBMITTED: 5,
      WON: 6,
      LOST: 7,
      ON_HOLD: 8,
    };

    const statusBeforeHold =
      selectedLead.getLeadById.leadStatus !== LeadStatusEnum['ON HOLD']
        ? selectedLead.getLeadById.leadStatus
        : getLeadStatusFromProbability(selectedLead.getLeadById.probability);

    const currentIndex = indexMap[statusBeforeHold];

    return statusKeys.slice(currentIndex).map(key => ({
      label: key,
      value: LeadStatusWithProbabilityEnum[key],
    }));
  };

  const handleChange = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleSubmit = () => {
    const followupDate = formatDate(formState.followupDate);
    const pqReceivedDate = formatDate(formState.pqReceivedDate);
    const pqSubmittedDate = formatDate(formState.pqSubmittedDate);
    const rfpReceivedDate = formatDate(formState.rfpReceivedDate);
    const rfpSubmittedDate = formatDate(formState.rfpSubmittedDate);
    const actualPODate = formatDate(formState.actualPODate);
    const refinedData = removeEmptyFields({ ...formState });

    delete refinedData.personType;

    createFollowup({
      variables: {
        input: {
          ...refinedData,
          followupDate,
          pqReceivedDate,
          pqSubmittedDate,
          rfpReceivedDate,
          rfpSubmittedDate,
          actualPODate,
        },
      },
      onCompleted: res => navigate(`/followups/${res.createFollowup._id}`),
    });
  };

  return (
    <Navbar
      goBackButtonConfig={{
        title: 'Create Event',
      }}
    >
      <Grid item container direction="column" xs={12} md={11} lg={9} xl={6} mt={1.5} rowGap={2.5}>
        <Note title="Event details should be updated within 72 hours" />
        <form
          onSubmit={e => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AutocompleteWithFetch
                fetch={getLeads}
                label="Lead"
                loading={loadingLeads}
                handleChange={val => {
                  setFormState(prev => ({
                    ...prev,
                    lead: val,
                    personType: '',
                    leadStatus: '',
                    followupContact: '',
                  }));
                  if (!!val?._id)
                    getLeadById({
                      variables: {
                        id: val._id,
                      },
                    });
                }}
                options={leads?.getLeads ?? []}
                value={formState.lead ?? { _id: '', name: '' }}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                label="Type"
                value={formState.followupType ?? ''}
                options={Object.keys(FollowupTypeEnum).map(k => ({
                  label: k,
                  value: FollowupTypeEnum[k],
                }))}
                onChange={val => handleChange('followupType', val)}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={formState.followupDate ?? null}
                    format="DD/MM/YYYY"
                    onChange={val => handleChange('followupDate', val)}
                    label="Event Date"
                    disableFuture
                    minDate={dayjs().subtract(3, 'day')}
                    slotProps={{
                      textField: {
                        variant: 'outlined',
                        fullWidth: true,
                        required: true,
                      },
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Select
                label="Person Type"
                disabled={!!!formState.lead || !!!formState.lead._id}
                value={formState.personType ?? ''}
                required
                fetchConfig={{
                  fetchFn: getLeadPeopleTypes,
                  loading: loadingLeadPeopleTypes,
                  variables: {
                    lead: formState?.lead?._id,
                  },
                }}
                onChange={val =>
                  setFormState(prev => ({
                    ...prev,
                    personType: val,
                    followupContact: '',
                  }))
                }
                options={
                  leadPeopleTypes?.getLeadPeopleTypes.map(o => ({ label: o, value: o })) ?? []
                }
              />
            </Grid>
            <Grid item xs={6}>
              <AutocompleteWithFetch
                fetch={getLeadPeople}
                label="Contact Person"
                loading={loadingLeadPeople}
                handleChange={val => handleChange('followupContact', val)}
                options={
                  leadPeople?.getLeadPeople.map(lp => ({
                    _id: lp._id,
                    name: lp.people.name,
                  })) ?? []
                }
                value={formState.followupContact ?? { _id: '', name: '' }}
                variables={{
                  filter: {
                    lead: formState.lead?._id,
                    type: formState.personType,
                  },
                }}
                required
                disabled={!!!formState.personType}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                label="Lead Status"
                value={formState.leadStatus ?? ''}
                onChange={val => handleChange('leadStatus', val)}
                options={getLeadStatusOptions()}
                required
                disabled={
                  selectedLead?.getLeadById?.leadStatus === LeadStatusEnum.LOST ||
                  selectedLead?.getLeadById?.leadStatus === LeadStatusEnum.WON
                }
              />
            </Grid>
            {formState.leadStatus === LeadStatusEnum.LOST && (
              <Grid item xs={6}>
                <Select
                  label="Lost Reason"
                  value={formState.lostReason ?? ''}
                  onChange={val => handleChange('lostReason', val)}
                  options={Object.keys(LeadLostReasonEnum).map(k => ({
                    label: k,
                    value: LeadLostReasonEnum[k],
                  }))}
                  required
                />
              </Grid>
            )}
            {formState.leadStatus === LeadStatusEnum['PQ RECEIVED'] && (
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={stringToDateFormatter(formState.pqReceivedDate)}
                      format="DD/MM/YYYY"
                      onChange={val => handleChange('pqReceivedDate', val)}
                      label="PQ Submission Date"
                      slotProps={{
                        textField: {
                          variant: 'outlined',
                          fullWidth: true,
                          required: true,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
            )}
            {formState.leadStatus === LeadStatusEnum['PQ SUBMITTED'] && (
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={stringToDateFormatter(formState.pqSubmittedDate)}
                      format="DD/MM/YYYY"
                      onChange={val => handleChange('pqSubmittedDate', val)}
                      label="PQ Submitted Date"
                      slotProps={{
                        textField: {
                          variant: 'outlined',
                          fullWidth: true,
                          required: true,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
            )}
            {formState.leadStatus === LeadStatusEnum['RFP RECEIVED'] && (
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={stringToDateFormatter(formState.rfpReceivedDate)}
                      format="DD/MM/YYYY"
                      onChange={val => handleChange('rfpReceivedDate', val)}
                      label="RFP Submission Date"
                      slotProps={{
                        textField: {
                          variant: 'outlined',
                          fullWidth: true,
                          required: true,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
            )}
            {formState.leadStatus === LeadStatusEnum['RFP SUBMITTED'] && (
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={stringToDateFormatter(formState.rfpSubmittedDate)}
                      format="DD/MM/YYYY"
                      onChange={val => handleChange('rfpSubmittedDate', val)}
                      label="RFP Submitted Date"
                      slotProps={{
                        textField: {
                          variant: 'outlined',
                          fullWidth: true,
                          required: true,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
            )}
            {formState.leadStatus === LeadStatusEnum.WON && (
              <>
                <Grid item xs={6}>
                  <TextField
                    value={formState.poNumber ?? ''}
                    onChange={e => handleChange('poNumber', e.target.value)}
                    label="PO Number"
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={formState.poAmount ?? ''}
                    label="PO Amount (Cr)"
                    onChange={e => handleChange('poAmount', +e.target.value)}
                    required
                    type="number"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Select
                    value={formState.poIssuedBy ?? ''}
                    onChange={val => handleChange('poIssuedBy', val)}
                    options={Object.keys(POIssuedByEnum).map(k => ({
                      label: k,
                      value: POIssuedByEnum[k],
                    }))}
                    label="PO Issued By"
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={stringToDateFormatter(formState.actualPODate)}
                        format="DD/MM/YYYY"
                        onChange={val => handleChange('actualPODate', val)}
                        label="Actual PO Date"
                        slotProps={{
                          textField: {
                            variant: 'outlined',
                            fullWidth: true,
                            required: true,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <TextField
                multiline
                minRows={3}
                size="small"
                value={formState.comments ?? ''}
                onChange={e => handleChange('comments', e.target.value)}
                label="Comments"
                required
              />
            </Grid>
            <Grid item xs={12} alignSelf="center" container justifyContent="end">
              <LoadingButton
                variant="contained"
                size="medium"
                type="submit"
                loading={creatingFollowup}
              >
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Navbar>
  );
};

export default CreateFollowup;
