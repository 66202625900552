import { Grid, SxProps, Theme } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LeadScopeEnum, LeadSegmentEnum, PQ, PqPriorityEnum, PqStatusEnum } from 'types';

import Card from 'components/Card';

import { truncateString } from 'utils/common';
import { getDate } from 'utils/formatHelper';
import { getEnumKeyFromValue } from 'utils/transformFn';

const PQsTemplate: React.FC<{ pqs: PQ[] }> = ({ pqs }) => {
  const navigate = useNavigate();

  const None = <i>None</i>;

  const pqCustomCSS = (pq: PQ) =>
    ({
      backgroundColor: (theme: Theme) => {
        if (pq.priority === PqPriorityEnum.LOW) {
          return theme.palette.funnelState.cold;
        } else if (pq.priority === PqPriorityEnum.MEDIUM) {
          return theme.palette.funnelState.warm;
        } else {
          return theme.palette.funnelState.hot;
        }
      },
      cursor: 'pointer',
    } as SxProps<Theme>);

  return (
    <Grid container columns={3} rowSpacing={3} columnSpacing={5} alignItems="center" mb={5}>
      {pqs.map(pq => (
        <Grid item key={pq.referenceId} width={'100%'} md={1.5} lg={1}>
          <Card
            headers={[
              truncateString(pq.lead.name, 15),
              pq.approvalStatus,
              getEnumKeyFromValue(LeadScopeEnum, pq.lead.scope) as string,
            ]}
            onClick={() => navigate(`${pq._id}`)}
            data={[
              {
                label: 'Segment',
                value: getEnumKeyFromValue(LeadSegmentEnum, pq.lead.segment),
              },
              {
                label: 'Status',
                value: getEnumKeyFromValue(PqStatusEnum, pq.status),
              },
              {
                label: 'Creation Date',
                value: !!pq.creationDate ? getDate(pq.creationDate) : None,
              },
              {
                label: 'Submitted Date',
                value: !!pq.submitionDate ? getDate(pq.submitionDate) : None,
              },
            ]}
            sx={pqCustomCSS(pq)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default PQsTemplate;
