import { gql, useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import React from 'react';
import { PO } from 'types';

import { FormInput, FormPanel } from 'components/FormPanel';

import { formatDate } from 'utils/transformFn';

const UPDATE_COMMERCIALS_MUTATION = gql`
  mutation UpdateRfpPo($input: UpdateRfpPoInput!) {
    updateRfpPo(input: $input) {
      _id
      referenceId
      poOrLOIReferenceNumber
      poOrLOIDate
    }
  }
`;

type UpdatePOMutationResponse = {
  updateRfpPo: PO;
};
type UpdatePOMutationVariables = {
  input: {
    _id: string;
    poOrLOIReferenceNumber: string;
    poOrLOIDate?: string;
  };
};

const UpdatePOForm: React.FC<{
  cb: (arg: PO) => void;
  po: PO;
}> = ({ cb, po }) => {
  const [updatePO, { loading: updatingPO }] = useMutation<
    UpdatePOMutationResponse,
    UpdatePOMutationVariables
  >(UPDATE_COMMERCIALS_MUTATION);

  const handleSubmit = (data: PO) => {
    updatePO({
      variables: {
        input: {
          _id: po._id,
          poOrLOIReferenceNumber: data.poOrLOIReferenceNumber,
          poOrLOIDate: formatDate(data.poOrLOIDate),
        },
      },
      onCompleted: res => cb(res.updateRfpPo),
    });
  };

  return (
    <FormPanel error={null} loading={updatingPO} onSubmit={handleSubmit} submitButtonLabel="Update">
      <FormInput
        fieldName="poOrLOIReferenceNumber"
        label="PO or LOI Reference Number"
        type="string"
        defaultValue={po.poOrLOIReferenceNumber}
        validators={{
          required: true,
        }}
      />
      <FormInput
        fieldName="poOrLOIDate"
        label="PO or LOI Date"
        type="date"
        defaultValue={!!po.poOrLOIDate ? dayjs(po.poOrLOIDate) : po.poOrLOIDate}
      />
    </FormPanel>
  );
};

export default UpdatePOForm;
