import { LazyQueryExecFunction, OperationVariables } from '@apollo/client';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import React, { useMemo } from 'react';
import { AutoCompleteOption } from 'types';

type AutocompleteWithFetchProps = {
  fetch: LazyQueryExecFunction<any, OperationVariables> | (() => void);
  label: string;
  handleChange: (arg: AutoCompleteOption | null) => void;
  disabled?: boolean;
  loading: boolean;
  variables?: any;
  required?: boolean;
  labelWithId?: boolean;
  error?: boolean;
  noOptionText?: string;
  onBlur?: () => void;
  addNewConfig?: {
    enableAddNew: boolean;
    onClick: () => void;
    addNewLabel?: string;
  };
} & (
  | {
      value: AutoCompleteOption;
      options: AutoCompleteOption[];
    }
  | {
      value: string;
      options: string[];
    }
);

const AutocompleteWithFetch: React.FC<AutocompleteWithFetchProps> = ({
  value,
  label,
  handleChange,
  disabled = false,
  required = false,
  variables = {},
  labelWithId = false,
  error = false,
  onBlur,
  addNewConfig = {
    enableAddNew: false,
    onClick: () => {},
  },
  ...props
}) => {
  const [open, setOpen] = React.useState(false);

  const transformedOptions = useMemo(() => {
    let transformed: AutoCompleteOption[] = [];

    if (Array.isArray(props.options) && props.options.length > 0) {
      if (typeof props.options[0] === 'string') {
        transformed = props.options.map(option => ({
          name: option,
          _id: option,
        }));
      } else {
        transformed = props.options as AutoCompleteOption[];
      }
    }

    if (addNewConfig.enableAddNew && !transformed.some(option => option._id === 'add-new')) {
      const addNewLabel = addNewConfig.addNewLabel ?? '+ Add New';
      transformed.push({ name: addNewLabel, _id: 'add-new' });
    }

    return transformed;
  }, [props.options, addNewConfig.enableAddNew, addNewConfig.addNewLabel]);

  return (
    <>
      <Autocomplete
        open={open}
        onOpen={() => {
          props.fetch({
            variables,
          });
          setOpen(true);
        }}
        onClose={() => setOpen(false)}
        disabled={disabled}
        value={typeof value === 'string' ? { _id: value, name: value, referenceId: value } : value}
        getOptionLabel={option => {
          if (!!option && !!!option._id) return '';

          if (labelWithId && !!option.referenceId) {
            return `${option.name} (${option.referenceId})`;
          }
          return option.name;
        }}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        fullWidth
        includeInputInList
        onChange={(e, val) => {
          if (val && val._id === 'add-new') {
            addNewConfig.onClick();
          } else {
            handleChange(val ? val : { name: '', _id: '' });
          }
        }}
        options={transformedOptions}
        loading={props.loading}
        noOptionsText={props.noOptionText}
        renderInput={params => (
          <TextField
            required={required}
            {...params}
            error={error}
            onBlur={onBlur ? () => onBlur() : undefined}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default AutocompleteWithFetch;
