import { gql, useLazyQuery, useMutation } from '@apollo/client';
import React from 'react';
import { PQ, RFP } from 'types';

import { FormInput, FormPanel } from 'components/FormPanel';

const UPDATE_RFP_MUTATION = gql`
  mutation UpdateRfp($input: UpdateRfpInput!) {
    updateRfp(input: $input) {
      _id
      referenceId
      pq {
        _id
        referenceId
        priority
        status
      }
    }
  }
`;

type UpdateRFPMutationResponse = {
  updateRfp: RFP;
};

type UpdateRFPMutationVariables = {
  input: {
    _id: string;
    pq: string;
  };
};

const GET_PQS_QUERY = gql`
  query GetPQs {
    getPQs {
      _id
      referenceId
      lead {
        name
      }
    }
  }
`;

type GetPQsQueryResponse = {
  getPQs: PQ[];
};

const RFPUpdateForm: React.FC<{ cb: () => void; rfp: RFP }> = ({ cb, rfp }) => {
  const [updateRFP, { loading: updatingRFP }] = useMutation<
    UpdateRFPMutationResponse,
    UpdateRFPMutationVariables
  >(UPDATE_RFP_MUTATION);

  const [getPQs, { loading: loadingPQs, data: pqs }] =
    useLazyQuery<GetPQsQueryResponse>(GET_PQS_QUERY);

  const handleSubmit = (data: Record<string, any>) => {
    updateRFP({
      variables: {
        input: {
          _id: rfp._id,
          pq: data.pq._id,
        },
      },
      onCompleted: _ => cb(),
    });
  };

  return (
    <FormPanel error={null} loading={updatingRFP} onSubmit={handleSubmit}>
      <FormInput
        fieldName="pq"
        type="auto_complete_with_fetch"
        autoCompleteConfig={{
          fetchOptionsFn: getPQs,
          options: !!pqs?.getPQs
            ? pqs.getPQs.map(pq => ({
                referenceId: pq.referenceId,
                _id: pq._id,
                name: pq.lead.name,
              }))
            : [],
          loading: loadingPQs,
        }}
        label="PQ"
        fullWidth
      />
    </FormPanel>
  );
};

export default RFPUpdateForm;
